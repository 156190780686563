export default Object.freeze({
  budgets: [
    {
      id: 1,
      value: '$5,000 - $10,000',
    },
    {
      id: 2,
      value: '$10,000 - $20,000',
    },
    {
      id: 3,
      value: 'Not Sure',
    },
  ],
})
