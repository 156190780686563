<template>
  <div>
    <div class="vd-margin-bottom-large">
      <input-component
        type="text"
        name="projectName"
        placeholder="Choose a memorable name"
        :label="isProjectReopen ? 'New Project Name' : 'Project Name'"
        :class="isProjectReopen ? 'vd-input-error' : ''"
        :value="projectName"
        :required="true"
        :container-custom-classes="setInputValidationClass('projectName')"
        @formInput="handleInputUpdate($event, 'projectName')"
      ></input-component>
    </div>

    <div v-if="isProjectReopen" class="vd-margin-bottom-large">
      <div class="vd-field">
        <label>Previous Project Name</label>
        <div class="vd-h4">
          {{ projectDetails.name }}
        </div>
      </div>
    </div>

    <div v-if="!isProjectReopen" class="vd-margin-bottom-large">
      <div
        :class="[
          'vd-field vd-required',
          setInputValidationClass('productType'),
        ]"
      >
        <label>Product Type</label>

        <model-list-select
          v-model="productType"
          :list="productTypesList"
          name="productType"
          option-value="id"
          option-text="name"
          placeholder="Select what best suits the content of the videos"
          @input="handleInputUpdate($event, 'productType')"
        ></model-list-select>
      </div>
    </div>

    <div v-else class="vd-margin-bottom-large">
      <input-read-only
        id="projectSummaryProjectTypeInput"
        label-content="Product Type"
        :input-content="productTypeName"
        :input-required="true"
        input-content-class="vms-project-summary__content--form__edit-input__read-only"
      ></input-read-only>
    </div>

    <div class="vd-margin-bottom-large">
      <div
        :class="[
          'vd-field vd-required',
          setInputValidationClass('relationshipManager'),
        ]"
      >
        <label>Relationship Manager</label>

        <model-list-select
          v-model="relationshipManager"
          name="relationshipManager"
          :list="accountManagers"
          option-value="id"
          option-text="full_name"
          placeholder="Select a Relationship Manager"
          @input="handleInputUpdate($event, 'relationshipManager')"
        ></model-list-select>
      </div>
    </div>

    <div class="vd-margin-bottom-large">
      <div
        :class="[
          'vd-field vd-required',
          setInputValidationClass('projectManager'),
        ]"
      >
        <label class="vd-position-relative">Project Manager</label>

        <model-list-select
          v-model="projectManager"
          name="projectManager"
          :list="producers"
          option-value="id"
          option-text="full_name"
          placeholder="Select a Project Manager"
          @input="handleInputUpdate($event, 'projectManager')"
        ></model-list-select>
      </div>
    </div>

    <div class="vd-margin-bottom-large">
      <input-component
        type="number"
        name="allocatedHours"
        label="Estimated Hours"
        placeholder="How many hours to complete the project?"
        :value="allocatedHours"
        :required="true"
        :container-custom-classes="setInputValidationClass('allocatedHours')"
        @formInput="handleInputUpdate($event, 'allocatedHours')"
      ></input-component>
    </div>

    <div class="vd-margin-bottom-large">
      <div
        :class="[
          'vd-field vd-required',
          setInputValidationClass('videoPrivacy'),
        ]"
      >
        <label>Video Privacy</label>

        <model-list-select
          v-model="videoPrivacy"
          name="videoPrivacy"
          :list="videoPrivacyOptionList"
          option-value="value"
          option-text="text"
          placeholder="Can the videos be shared publicly?"
          @input="handleInputUpdate($event, 'videoPrivacy')"
        ></model-list-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import {
  required,
  minLength,
  minValue,
  integer,
} from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import InputReadOnly from '@components/form/input-read-only'
import VmsConstants from '@configs/vms-constants'
import { size } from 'lodash'

export default {
  components: {
    InputComponent,
    ModelListSelect,
    InputReadOnly,
  },

  mixins: [FormMixin],

  data() {
    return {
      projectName: '',
      productType: null,
      relationshipManager: null,
      projectManager: null,
      productTypeName: '',
      videoPrivacy: null,
      allocatedHours: 0,
    }
  },

  validations: {
    projectName: {
      required,
      minLength: minLength(1),
    },

    productType: {
      required,
      minLength: minLength(1),
    },

    relationshipManager: {
      required,
      minLength: minLength(1),
    },

    projectManager: {
      required,
      minLength: minLength(1),
    },

    videoPrivacy: {
      required,
      minLength: minLength(1),
    },

    allocatedHours: {
      required,
      integer,
      minLength: minValue(1),
    },
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      productTypesList: 'project/productTypesList',
      producers: 'user/producers',
      accountManagers: 'user/accountManagers',
      newProjectFormPayload: 'project/newProjectFormPayload',
      projectContacts: 'project/contact/projectContacts',
    }),

    isFormInvalid() {
      return this.$v.$invalid
    },

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    videoPrivacyOptionList() {
      return [
        {
          value: VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PRIVATE,
          text: 'Private',
        },
        {
          value: VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PUBLIC,
          text: 'Public',
        },
      ]
    },
  },

  watch: {
    isFormInvalid(val) {
      this.updateFormSaveBtnDisabled(val)
    },

    projectName() {
      this.updateNewProjectFormPayload({
        name: this.projectName,
      })
    },

    productType() {
      this.updateNewProjectFormPayload({
        product_type_id: this.productType,
      })
    },

    relationshipManager() {
      this.updateNewProjectFormPayload({
        account_manager_id: this.relationshipManager,
      })
    },

    projectManager() {
      this.updateNewProjectFormPayload({
        producer_id: this.projectManager,
      })
    },

    videoPrivacy() {
      this.updateNewProjectFormPayload({
        video_privacy: this.videoPrivacy,
      })
    },

    allocatedHours() {
      this.updateNewProjectFormPayload({
        budgeted_hours: this.allocatedHours,
      })
    },
  },

  mounted() {
    // validate form on mount when project is reopen
    if (this.isProjectReopen) {
      this.$v.$touch()
    }

    // Set the form save btn disabled by default
    this.updateFormSaveBtnDisabled(true)

    // Set the contact ID and office ID by default from the selected contact
    this.updateNewProjectFormPayload({
      contact_id:
        size(this.projectContacts) > 0 ? this.projectContacts[0].id : null,
      office_id: this.projectDetails.office.id,
    })
  },

  created() {
    this.initProjectReopenFields()
  },

  methods: {
    ...mapActions({
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      updateNewProjectFormPayload: 'project/updateNewProjectFormPayload',
    }),

    initProjectReopenFields() {
      if (this.isProjectReopen) {
        this.productType = this.projectDetails.product_type.id
        this.productTypeName = this.projectDetails.product_type.name
        this.relationshipManager = this.projectDetails.account_manager.id
        this.projectManager = this.projectDetails.producer.id
        this.videoPrivacy = this.projectDetails.video_privacy
        this.allocatedHours = this.projectDetails.budgeted_hours
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/select';
@import '@styles/plugins/vue-search-select';
</style>
