export default Object.freeze({
  platforms: [
    {
      id: 1,
      value: 'Facebook',
    },
    {
      id: 2,
      value: 'Instagram',
    },
    {
      id: 3,
      value: 'IGTV',
    },
    {
      id: 4,
      value: 'Youtube',
    },
    {
      id: 5,
      value: 'Linkedin',
    },
    {
      id: 6,
      value: 'TVC',
    },
    {
      id: 7,
      value: 'Story',
    },
    {
      id: 8,
      value: 'EDM',
    },
    {
      id: 9,
      value: 'Other',
    },
  ],
})
