<template>
  <div>
    <div class="vd-margin-bottom-40">
      <project-summary-editable
        v-if="isEditMode"
        name="projectSummaryProjectName"
        :value="projectDetails.name"
        label="Project Name"
        type="text"
        placeholder="Choose a memorable name"
        :required="true"
        :container-custom-classes="setInputValidationClass('projectName')"
        @update:project-form-input="
          handleUpdateProjectInputForm($event, 'projectName')
        "
      ></project-summary-editable>

      <project-summary-read-only
        v-else
        type="text"
        name="projectSummaryProjectNameInput"
        label="Project Name"
        :value="projectDetails.name"
        :required="true"
      ></project-summary-read-only>
    </div>

    <div class="vd-margin-bottom-40">
      <project-summary-editable
        v-if="isEditMode && canUpdateProductTypeField"
        id="projectSummaryProductTypeInput"
        :value="productTypeId"
        :dropdown-select-list="productTypesList"
        :dropdown-select-config="productTypeDropdownConfig"
        :container-custom-classes="setInputValidationClass('productTypeId')"
        placeholder="Select what best suits the content of the videos"
        label="Product Type"
        type="dropdown-select"
        @update:model-list-select="handleProductTypeUpdate"
      ></project-summary-editable>

      <project-summary-read-only
        v-else
        type="text"
        name="projectSummaryProductTypeInput"
        label="Product Type"
        :value="selectedProductTypeName"
        :required="true"
        :display-input-component="false"
        :is-editable="canUpdateProductTypeField"
      >
        <div
          v-if="selectedProductTypeIconClass"
          slot="input-prepend"
          class="vms-project-summary__input-prepend"
        >
          <i
            :class="[
              'vms-project-summary__input-prepend--icon vd-h3 vd-icon',
              selectedProductTypeIconClass,
            ]"
          >
          </i>
        </div>
      </project-summary-read-only>
    </div>

    <div class="vd-margin-bottom-40">
      <div class="vd-margin-bottom-30">
        <project-summary-editable
          v-if="isEditMode"
          id="projectSummaryRelationshipManagerInput"
          :value="relationshipManagerId"
          :dropdown-select-list="accountManagers"
          :dropdown-select-config="dropdownConfig"
          :container-custom-classes="
            setInputValidationClass('relationshipManagerId')
          "
          placeholder="Select a Relationship Manager"
          label="Relationship Manager"
          type="dropdown-select"
          @update:model-list-select="handleRelationshipManagerUpdate"
        ></project-summary-editable>

        <project-summary-read-only
          v-else
          type="text"
          name="projectSummaryRelationshipManagerInput"
          label="Relationship Manager"
          :value="projectDetails.account_manager.full_name"
          :required="true"
        >
          <staff-popover
            v-if="projectDetails.account_manager.id"
            slot="input-prepend"
            class="vms-project-summary__input-prepend"
            :selected-user="projectDetails.account_manager"
            :tooltip-id="`relationship-manager-${projectDetails.account_manager.id}`"
            :label-avatar-font-size="25"
            label-avatar-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
            label-avatar-image-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
          ></staff-popover>
        </project-summary-read-only>
      </div>
    </div>

    <div class="vd-margin-bottom-40">
      <div class="vd-margin-bottom-30">
        <project-summary-editable
          v-if="isEditMode"
          id="projectSummaryProjectManagerInput"
          :value="projectManagerId"
          :dropdown-select-list="producers"
          :dropdown-select-config="dropdownConfig"
          :container-custom-classes="
            setInputValidationClass('projectManagerId')
          "
          placeholder="Select a Project Manager"
          label="Project Manager"
          type="dropdown-select"
          @update:model-list-select="handleProjectManagerUpdate"
        ></project-summary-editable>

        <project-summary-read-only
          v-else
          type="text"
          name="projectSummaryProjectManagerInput"
          label="Project Manager"
          :value="projectDetails.producer.full_name"
          :required="true"
        >
          <staff-popover
            v-if="projectDetails.producer.id"
            slot="input-prepend"
            class="vms-project-summary__input-prepend"
            :selected-user="projectDetails.producer"
            :tooltip-id="`project-manager-${projectDetails.producer.id}`"
            :label-avatar-font-size="25"
            label-avatar-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
            label-avatar-image-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
          ></staff-popover>
        </project-summary-read-only>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import FormMixin from '@mixins/forms-mixin'
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from 'vuelidate/lib/validators'
import ProjectSummaryEditable from '@views/project/summary/components/form-sections/project-summary-editable'
import ProjectSummaryReadOnly from '@views/project/summary/components/form-sections/project-summary-read-only'
import { size, find, isNil, merge } from 'lodash'
import VmsConstants from '@configs/vms-constants'
import ProductTypesConfig from '@configs/product-types'
import StaffPopover from '@components/staff-popover/staff-popover'

export default {
  components: {
    ProjectSummaryEditable,
    ProjectSummaryReadOnly,
    StaffPopover,
  },

  mixins: [FiltersMixin, FormMixin],

  data() {
    return {
      projectName: '',
      relationshipManagerId: null,
      projectManagerId: null,
      productTypeId: null,
      defaultPayload: {},
    }
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      isEditMode: 'project/isEditMode',
      producers: 'user/producers',
      accountManagers: 'user/accountManagers',
      productTypesList: 'project/productTypesList',
      preProductions: 'project/pre-production/preProductions',
      filmingDetails: 'project/shoot/filmingDetails',
      deliveries: 'project/delivery/deliveries',
      editProjectSummaryFormPayload: 'project/editProjectSummaryFormPayload',
    }),

    dropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'full_name',
      }
    },

    productTypeDropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'name',
      }
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    selectedProductTypeName() {
      return this.projectDetails.product_type?.name || ''
    },

    selectedProductTypeIconClass() {
      const productType = find(ProductTypesConfig.productTypes, {
        id: this.projectDetails.product_type?.id,
      })

      return productType?.icon || ''
    },

    hasProductType() {
      return !isNil(this.projectDetails.product_type)
    },

    isDraftProject() {
      return (
        this.projectDetails.project_status_id ===
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT
      )
    },

    hasOrderJobs() {
      return (
        size(this.preProductions) > 0 ||
        size(this.filmingDetails.data) > 0 ||
        size(this.deliveries.data) > 0
      )
    },

    canUpdateProductTypeField() {
      return (
        (this.isDraftProject && !this.hasOrderJobs) ||
        (!this.isDraftProject && !this.hasOrderJobs && !this.hasProductType)
      )
    },

    changedPayload() {
      return {
        projectName: this.projectName,
        relationshipManagerId: this.relationshipManagerId,
        projectManagerId: this.projectManagerId,
        productTypeId: this.productTypeId,
      }
    },
  },

  validations: {
    projectName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(255),
    },
    relationshipManagerId: { required },
    projectManagerId: { required },
    productTypeId: {
      required: requiredIf(function () {
        return this.canUpdateProductTypeField
      }),
    },
  },

  watch: {
    relationshipManagerId() {
      this.updateEditProjectSummaryFormPayload({
        account_manager_id: this.relationshipManagerId,
      })
    },

    projectManagerId() {
      this.updateEditProjectSummaryFormPayload({
        producer_id: this.projectManagerId,
      })
    },

    productTypeId() {
      this.updateEditProjectSummaryFormPayload(
        merge(this.editProjectSummaryFormPayload, {
          product_type_id: this.productTypeId,
        })
      )
    },

    isFormInvalid() {
      this.updateProjectInfoFormInvalid(this.isFormInvalid)
    },

    changedPayload(val) {
      this.$emit('update:changed-payload', val)
    },
  },

  mounted() {
    this.initProjectInfoPayloadData()
    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      updateEditProjectSummaryFormPayload:
        'project/updateEditProjectSummaryFormPayload',
      updateProjectInfoFormInvalid: 'project/updateProjectInfoFormInvalid',
    }),

    initProjectInfoPayloadData() {
      this.updateProjectInfoFormInvalid(this.isFormInvalid)

      const existingRelationshipManager = find(this.accountManagers, {
        id: this.projectDetails.account_manager_id,
      })

      const existingProjectManager = find(this.producers, {
        id: this.projectDetails.producer_id,
      })

      this.projectName = this.projectDetails.name

      this.relationshipManagerId = existingRelationshipManager
        ? existingRelationshipManager.id
        : null

      this.projectManagerId = existingProjectManager
        ? existingProjectManager.id
        : null

      if (this.canUpdateProductTypeField) {
        const existingProductType = find(this.productTypesList, {
          id: this.projectDetails.product_type?.id,
        })

        this.productTypeId = existingProductType ? existingProductType.id : null
      }

      this.handleUpdateProjectInputForm()
    },

    handleUpdateProjectInputForm(inputValue, inputName) {
      this.updateEditProjectSummaryFormPayload({
        name: inputValue,
      })

      this.handleInputUpdate(inputValue, inputName)
    },

    handleRelationshipManagerUpdate(relationshipManager) {
      this.relationshipManagerId = relationshipManager
      this.handleInputUpdate(relationshipManager, 'relationshipManagerId')
    },

    handleProjectManagerUpdate(projectManager) {
      this.projectManagerId = projectManager
      this.handleInputUpdate(projectManager, 'projectManagerId')
    },

    handleProductTypeUpdate(productType) {
      this.productTypeId = productType
      this.handleInputUpdate(productType, 'productTypeId')
    },

    initDefaultPayload() {
      this.defaultPayload = {
        projectName: this.projectName,
        relationshipManagerId: this.relationshipManagerId,
        projectManagerId: this.projectManagerId,
        productTypeId: this.productTypeId,
      }

      this.$emit('init:default-payload', this.defaultPayload)
    },
  },
}
</script>
