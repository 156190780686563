import { isNil } from 'lodash'

/**
 * Helper mixins for style.
 * @module mixins/style
 */
import StatusesConfig from '@configs/statuses'
import { find } from 'lodash'

export default {
  directives: {
    /**
     * Add a custom background image to the element
     * @example <div v-custom-background-image="'http://image.url'"></div>
     */
    'custom-background-image': {
      inserted: (el, binding) => {
        if (binding.value) {
          el.style.backgroundImage = `url('${binding.value}')`
        }
      },
    },
  },

  methods: {
    /**
     * Get the status classes for the specified type
     *
     * @param {string} type - the name type of the object we want to get status classes for
     * @param {number} statusId - the selected status id
     * @returns {object} the object of the selected status id consisting of id, container class and label class
     */
    getStatusClass(type, statusId) {
      const statusClass = find(StatusesConfig[type], {
        id: statusId,
      })

      // If the status class is undefined, we will return the default class data
      if (isNil(statusClass)) {
        return find(StatusesConfig[type], {
          id: 'default',
        })
      }

      return statusClass
    },
  },
}
