var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{staticClass:"vd-full-width",attrs:{"index-name":"user_offices_index","search-client":_vm.searchClient}},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return _c('input-component',{attrs:{"current-refinement":currentRefinement,"type":"text","name":"projectSummaryAlgoliaSearch","label":"Search","is-algolia-search-type":true},on:{"update:current-refinement":function($event){return refine($event)}}})}}])}),_c('ais-configure',{attrs:{"filters":_vm.filters}}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
var hits = ref.hits;
return _c('div',{staticClass:"vd-position-relative"},[(!hits.length)?_c('no-results',{attrs:{"query":query}}):_vm._e(),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _c('result-hits',{attrs:{"query-items":items},on:{"update:search-off-focus":_vm.toggleShowAddContactsList}})}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }