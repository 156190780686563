<template>
  <div
    v-custom-background-image="projectDetails.thumbnail_url"
    :class="[
      'vd-full-width vd-border-radius-5 vd-position-relative vd-margin-bottom-30',
      containerClass,
    ]"
  ></div>
</template>

<script>
import { mapGetters } from 'vuex'
import StyleMixin from '@mixins/styles-mixin'

export default {
  mixins: [StyleMixin],

  props: {
    containerClass: {
      type: String,
      required: false,
      default: 'vms-project-summary__thumbnail',
    },
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
    }),
  },
}
</script>
