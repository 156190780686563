<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header vms-modal__header">
      <div class="vd-h4 vd-light-blue">{{ modalTitle }}</div>
      <div class="vms-modal__header--close close vd-black">
        <span id="closeContactFormModalSpan" @click="closeContactFormModal">
          x
        </span>
      </div>
    </div>

    <div slot="modal-body" class="modal-body">
      <div v-if="isLoading" class="vd-margin-block-large">
        <default-loader></default-loader>
      </div>
      <v-app v-else class="vd-margin-bottom-medium vd-padding-medium">
        <div class="row">
          <div class="col-md-6">
            <input-component
              v-model="firstName"
              type="text"
              name="contactFormModalFirstNameInput"
              label="First Name"
              placeholder="Enter first name"
              :disabled="isContactLoading"
              :required="true"
              :container-custom-classes="[setInputValidationClass('firstName')]"
              @formInput="handleInputUpdate($event, 'firstName')"
            ></input-component>
          </div>

          <div class="col-md-6">
            <input-component
              v-model="lastName"
              type="text"
              name="contactFormModalLastNameInput"
              label="Last Name"
              placeholder="Enter last name"
              :disabled="isContactLoading"
              :required="true"
              :container-custom-classes="[setInputValidationClass('lastName')]"
              @formInput="handleInputUpdate($event, 'lastName')"
            ></input-component>
          </div>

          <div class="col-md-6">
            <input-component
              v-model="email"
              type="text"
              name="contactFormModalEmailInput"
              label="Email"
              placeholder="Enter email address"
              :readonly="isExistingContact"
              :disabled="isExistingContact || isContactLoading"
              :required="true"
              :container-custom-classes="[setInputValidationClass('email')]"
              @formInput="handleInputUpdate($event, 'email')"
            ></input-component>
          </div>

          <div class="col-md-6">
            <input-component
              v-model="phone"
              type="text"
              name="contactFormModalPhoneInput"
              label="Phone"
              placeholder="Enter phone number"
              :disabled="isContactLoading"
              :required="true"
              :container-custom-classes="[setInputValidationClass('phone')]"
              @formInput="handleInputUpdate($event, 'phone')"
            ></input-component>
          </div>

          <div class="col-md-6">
            <input-read-only
              id="contactFormModalCompanyName"
              type="text"
              label-content="Company"
              :input-content="projectCompany.name"
              :input-required="true"
            ></input-read-only>
          </div>

          <div class="col-md-6">
            <v-select
              id="projectContactFormOfficesSelect"
              v-model="offices"
              label="Offices"
              item-text="name"
              item-value="id"
              placeholder="Select offices"
              persistent-placeholder
              :class="[
                'vd-field vd-required',
                setInputValidationClass('offices'),
              ]"
              :items="contactOffices"
              multiple
              chips
              small-chips
              :deletable-chips="true"
              :hide-selected="true"
              :disabled="isContactLoading"
              :required="true"
              :menu-props="menuProps"
              no-data-text="No results to display"
              @change="handleInputUpdate($event, 'offices')"
            >
            </v-select>
          </div>

          <div class="col-md-6">
            <v-select
              id="projectContactFormRolesSelect"
              v-model="roles"
              label="Client Hub Roles"
              item-text="name"
              item-value="id"
              placeholder="Select roles"
              persistent-placeholder
              :class="[
                'vd-field vd-required',
                setInputValidationClass('roles'),
              ]"
              :items="contactRoles"
              multiple
              chips
              small-chips
              :deletable-chips="true"
              :hide-selected="true"
              :disabled="isContactLoading"
              :required="true"
              :menu-props="menuProps"
              no-data-text="No results to display"
              @change="handleInputUpdate($event, 'roles')"
            >
            </v-select>
          </div>
        </div>
      </v-app>
    </div>
    <div slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          class="vd-btn-small vd-btn-grey vd-margin-right-small"
          :disabled="isLoading || isContactLoading"
          @click="closeContactFormModal"
        >
          Cancel
        </button>
        <button
          class="vd-btn-small vd-btn-blue"
          :disabled="isSaveDisabled"
          @click="handleSave"
        >
          {{ isContactLoading ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import DefaultModal from '@components/modal/default-modal'
import SweetAlert from '@plugins/sweet-alert'
import InputComponent from '@components/form/form-input'
import InputReadOnly from '@components/form/input-read-only'
import DefaultLoader from '@components/loaders/default-loader'
import FormMixin from '@mixins/forms-mixin'
import Vuetify from 'vuetify'
import VmsConstants from '@configs/vms-constants'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { isEmpty, map, isEqual, assign, includes, unset } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    DefaultModal,
    InputComponent,
    InputReadOnly,
    DefaultLoader,
  },

  mixins: [FormMixin],

  vuetify: new Vuetify(),

  validations: {
    firstName: {
      required,
    },

    lastName: {
      required,
    },

    email: {
      required,
      email,
    },

    phone: {
      required,
    },

    offices: {
      required,
      minLength: minLength(1),
    },

    roles: {
      required,
      minLength: minLength(1),
    },
  },

  data() {
    return {
      menuProps: { offsetY: true, closeOnClick: true },
      id: null,
      company: '',

      // form input fields data
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      offices: [],
      roles: [],

      // initial data provided to the form
      initialFormData: {},
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      projectCompany: 'project/projectCompany',
      projectDetails: 'project/projectDetails',
      isUserDetailsLoading: 'user/isUserDetailsLoading',
      isContactLoading: 'user/isContactLoading',
      isCompanyOfficesLoading: 'company/isCompanyOfficesLoading',
      isExternalRolesLoading: 'role/isExternalRolesLoading',
      selectedUserId: 'user/selectedUserId',
      userDetails: 'user/userDetails',
      companyOffices: 'company/companyOffices',
      externalRoles: 'role/externalRoles',
    }),

    contactRoles() {
      // disable default role
      const contactRoles = map(this.externalRoles, (role) => {
        if (role.id === VmsConstants.userRoles.USER_ROLE_ID_CLIENT) {
          return assign(role, {
            disabled: true,
          })
        }
        return role
      })

      return contactRoles
    },

    contactOffices() {
      // disable default and initial offices
      const contactOffices = map(this.companyOffices, (office) => {
        if (includes(this.initialFormData.office_ids, office.id)) {
          return assign(office, {
            disabled: true,
          })
        }
        return office
      })

      return contactOffices
    },

    isLoading() {
      return (
        this.isCompanyOfficesLoading ||
        this.isExternalRolesLoading ||
        this.isUserDetailsLoading
      )
    },

    hasChanges() {
      return !isEqual(this.formData, this.initialFormData)
    },

    isSaveDisabled() {
      return (
        this.isLoading ||
        this.isContactLoading ||
        !this.hasChanges ||
        this.$v.$invalid
      )
    },

    isExistingContact() {
      return !!this.selectedUserId
    },

    modalTitle() {
      return this.isExistingContact ? 'Edit Contact' : 'Create Contact'
    },

    formData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        office_ids: this.offices,
        role_ids: this.roles,
      }
    },
  },

  watch: {
    userDetails: {
      handler(user) {
        if (!isEmpty(user)) {
          this.id = user.id

          // payloads
          this.firstName = user.first_name
          this.lastName = user.last_name
          this.email = user.email
          this.phone = user.phone
          this.offices = map(user.offices, 'id')
          this.roles = map(user.roles, 'id')

          this.saveInitialFormData()

          // run validation when contact is an existing user
          this.$v.$touch()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions({
      toggleContactFormShow: 'user/toggleContactFormShow',
      setSelectedUserId: 'user/setSelectedUserId',
      getUserDetails: 'user/getUserDetails',
      setUserDetails: 'user/setUserDetails',
      getCompanyOffices: 'company/getCompanyOffices',
      getExternalRoles: 'role/getExternalRoles',
      updateContactDetails: 'user/updateContactDetails',
      createNewContact: 'user/createNewContact',
    }),

    init() {
      if (this.selectedUserId) {
        this.getUserDetails({
          userId: this.selectedUserId,
          includes: ApiCustomIncludes.user,
        })
      }

      this.getCompanyOffices({
        companyId: this.projectCompany.id,
      })

      this.getExternalRoles({
        includes: [],
      })

      // load default offices and roles
      this.offices = [this.projectDetails.office.id]
      this.roles = [VmsConstants.userRoles.USER_ROLE_ID_CLIENT]

      // trigger validation for offices and roles
      this.$v.offices.$touch()
      this.$v.roles.$touch()

      this.saveInitialFormData()
    },

    saveInitialFormData() {
      this.initialFormData = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        phone: this.phone,
        office_ids: this.offices,
        role_ids: this.roles,
      }
    },

    closeContactFormModal() {
      if (this.isLoading) {
        return
      }

      if (!this.hasChanges) {
        this.setSelectedUserId(null)
        this.setUserDetails(null)
        this.toggleContactFormShow()
        return
      }

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setSelectedUserId(null)
          this.setUserDetails(null)
          this.toggleContactFormShow()
        }
      })
    },

    handleSave() {
      const payload = assign(
        {
          company_id: this.projectCompany.id,
          project_id: this.projectDetails.id,
        },
        this.formData
      )

      // update when contact exists else create new
      if (this.isExistingContact) {
        // add id to payload
        assign(payload, { id: this.id })

        // remove email from payload - email is not editable
        unset(payload, 'email')

        this.updateContactDetails({ payload })
      } else {
        this.createNewContact({ payload })
      }
    },
  },
}
</script>
