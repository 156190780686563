var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vd-margin-bottom-large"},[_c('input-component',{class:_vm.isProjectReopen ? 'vd-input-error' : '',attrs:{"type":"text","name":"projectName","placeholder":"Choose a memorable name","label":_vm.isProjectReopen ? 'New Project Name' : 'Project Name',"value":_vm.projectName,"required":true,"container-custom-classes":_vm.setInputValidationClass('projectName')},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'projectName')}}})],1),(_vm.isProjectReopen)?_c('div',{staticClass:"vd-margin-bottom-large"},[_c('div',{staticClass:"vd-field"},[_c('label',[_vm._v("Previous Project Name")]),_c('div',{staticClass:"vd-h4"},[_vm._v(" "+_vm._s(_vm.projectDetails.name)+" ")])])]):_vm._e(),(!_vm.isProjectReopen)?_c('div',{staticClass:"vd-margin-bottom-large"},[_c('div',{class:[
        'vd-field vd-required',
        _vm.setInputValidationClass('productType') ]},[_c('label',[_vm._v("Product Type")]),_c('model-list-select',{attrs:{"list":_vm.productTypesList,"name":"productType","option-value":"id","option-text":"name","placeholder":"Select what best suits the content of the videos"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'productType')}},model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=$$v},expression:"productType"}})],1)]):_c('div',{staticClass:"vd-margin-bottom-large"},[_c('input-read-only',{attrs:{"id":"projectSummaryProjectTypeInput","label-content":"Product Type","input-content":_vm.productTypeName,"input-required":true,"input-content-class":"vms-project-summary__content--form__edit-input__read-only"}})],1),_c('div',{staticClass:"vd-margin-bottom-large"},[_c('div',{class:[
        'vd-field vd-required',
        _vm.setInputValidationClass('relationshipManager') ]},[_c('label',[_vm._v("Relationship Manager")]),_c('model-list-select',{attrs:{"name":"relationshipManager","list":_vm.accountManagers,"option-value":"id","option-text":"full_name","placeholder":"Select a Relationship Manager"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'relationshipManager')}},model:{value:(_vm.relationshipManager),callback:function ($$v) {_vm.relationshipManager=$$v},expression:"relationshipManager"}})],1)]),_c('div',{staticClass:"vd-margin-bottom-large"},[_c('div',{class:[
        'vd-field vd-required',
        _vm.setInputValidationClass('projectManager') ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("Project Manager")]),_c('model-list-select',{attrs:{"name":"projectManager","list":_vm.producers,"option-value":"id","option-text":"full_name","placeholder":"Select a Project Manager"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'projectManager')}},model:{value:(_vm.projectManager),callback:function ($$v) {_vm.projectManager=$$v},expression:"projectManager"}})],1)]),_c('div',{staticClass:"vd-margin-bottom-large"},[_c('input-component',{attrs:{"type":"number","name":"allocatedHours","label":"Estimated Hours","placeholder":"How many hours to complete the project?","value":_vm.allocatedHours,"required":true,"container-custom-classes":_vm.setInputValidationClass('allocatedHours')},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'allocatedHours')}}})],1),_c('div',{staticClass:"vd-margin-bottom-large"},[_c('div',{class:[
        'vd-field vd-required',
        _vm.setInputValidationClass('videoPrivacy') ]},[_c('label',[_vm._v("Video Privacy")]),_c('model-list-select',{attrs:{"name":"videoPrivacy","list":_vm.videoPrivacyOptionList,"option-value":"value","option-text":"text","placeholder":"Can the videos be shared publicly?"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'videoPrivacy')}},model:{value:(_vm.videoPrivacy),callback:function ($$v) {_vm.videoPrivacy=$$v},expression:"videoPrivacy"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }