<template>
  <div>
    <div
      v-click-outside="handleContactsListBlur"
      :class="contactHeaderContainerClass"
    >
      <span
        :class="['vms-project-summary__contacts--label', contactsLabelClass]"
      >
        {{ contactsLabelText }}
      </span>

      <span
        v-if="canUpdateContacts"
        id="toggleContactFormShowSpan"
        class="vd-a vd-margin-right-medium"
        @click="toggleContactFormShow"
      >
        Create new contact
      </span>

      <div
        v-if="canUpdateContacts"
        id="addProjectContactDiv"
        :class="[
          'vms-project-summary__contacts--icon-add vd-margin-right-extra-small',
          showAddContactsList ? 'vd-icon-close-circle' : 'vd-icon-plus-circle',
        ]"
        @click="toggleShowAddContactsList"
      ></div>

      <project-contacts-algolia
        v-if="showAddContactsList"
      ></project-contacts-algolia>
    </div>
    <div class="vms-project-summary__contacts--list-container">
      <div
        v-for="contact in contacts"
        :key="contact.id"
        class="vms-project-summary__contacts--list vd-border-light-grey vd-padding-bottom-20 vd-padding-top-20 vd-padding-right-40 vd-position-relative"
      >
        <div class="row no-gutters">
          <staff-popover
            :selected-user="contact"
            :tooltip-id="`contact-${contact.id}`"
            :label-avatar-font-size="45"
            :is-editable="canUpdateContacts"
            label-avatar-class="vms-project-summary__contacts--profile vd-margin-right-medium"
            label-avatar-image-class="vms-project-summary__contacts--profile vd-margin-right-medium"
            @click:edit="openContactFormModal"
          ></staff-popover>

          <div
            class="vms-project-summary__contacts--info vd-padding-right-medium vd-position-relative"
          >
            <div class="vd-h5 vd-dark-grey">Name</div>
            <div class="vd-h4 vd-black">
              {{ contact.first_name }} {{ contact.last_name }}
            </div>
          </div>

          <div class="clearfix"></div>

          <div
            v-if="
              contact.is_removable &&
              canUpdateContacts &&
              !projectContactsLoading
            "
            class="removeProjectContactDiv vms-project-summary__contacts--icon-remove vd-icon-close-round"
            @click="handleRemoveContactClick(contact)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectContactsAlgolia from '@views/project/summary/components/project-contacts/project-contacts-algolia'
import ClickOutside from 'vue-click-outside'
import StaffPopover from '@components/staff-popover/staff-popover'

export default {
  directives: {
    ClickOutside,
  },

  components: {
    ProjectContactsAlgolia,
    StaffPopover,
  },

  props: {
    contactHeaderContainerClass: {
      type: String,
      required: false,
      default: '',
    },

    contactsLabelText: {
      type: String,
      required: false,
      default: 'Contacts',
    },

    contactsLabelClass: {
      type: String,
      required: false,
      default: 'vd-light-blue',
    },

    canUpdateContacts: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      contacts: 'project/contact/projectContacts',
      showAddContactsList: 'project/contact/showAddContactsList',
      projectDetails: 'project/projectDetails',
      projectContactsLoading: 'project/contact/projectContactsLoading',
    }),
  },

  methods: {
    ...mapActions({
      toggleShowAddContactsList: 'project/contact/toggleShowAddContactsList',
      removeProjectContact: 'project/contact/removeProjectContact',
      setSelectedUserId: 'user/setSelectedUserId',
      toggleContactFormShow: 'user/toggleContactFormShow',
    }),

    handleRemoveContactClick(contact) {
      if (!this.projectContactsLoading) {
        this.removeProjectContact({
          projectId: this.projectDetails.id,
          contact_id: contact.id,
          includes: null,
          contact_name: contact.first_name,
        })
      }
    },

    handleContactsListBlur() {
      if (this.showAddContactsList) {
        this.toggleShowAddContactsList()
      }
    },

    openContactFormModal(contact) {
      this.setSelectedUserId(contact.id)
      this.toggleContactFormShow()
    },
  },
}
</script>
