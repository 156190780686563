<template>
  <div
    v-if="!isMobileDevice && copyContent"
    :class="[
      'vms-copy-clipboard--container vd-position-relative',
      containerClass,
    ]"
  >
    <div
      class="vms-copy-clipboard--container vd-full-width"
      @click="copyToClipboard"
    >
      <slot>
        <div
          v-if="showIcon && !showLabelAsTooltip && !showAsButton"
          :class="['vms-copy-clipboard--icon-link', customIcon]"
        ></div>

        <div v-if="showText" :class="copyTextLabelClass">
          <span :class="copyTextContentClass"> {{ copyText }} </span>
        </div>

        <text-popover
          v-if="showLabelAsTooltip"
          :tooltip-text="copyText"
          :tooltip-icon="customIcon"
          :tooltip-id="tooltipId"
          tooltip-placement="top"
        ></text-popover>

        <button v-if="showAsButton" :class="buttonClass">
          <div
            v-if="showIcon"
            :class="['vms-copy-clipboard--icon-link', customIcon]"
          ></div>

          {{ copyText }}
        </button>
      </slot>
    </div>

    <div
      v-if="copied && !showToastAlert"
      class="vms-copy-clipboard--icon--container vd-position-absolute vd-background-lightest-grey vd-padding-inline-small vd-border-radius-6 vd-box-shadow"
    >
      <span class="vd-icon-tick-green vms-copy-clipboard--icon-success"></span>
      <span class="vms-copy-clipboard--label vd-green vd-h5">
        {{ successMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CopyToClipboard from 'copy-text-to-clipboard'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },

  props: {
    containerClass: {
      type: String,
      required: false,
      default: '',
    },

    customIcon: {
      type: String,
      required: false,
      default: 'vd-icon-link-default',
    },

    copyText: {
      type: String,
      required: false,
      default: 'Copy',
    },

    successMessage: {
      type: String,
      required: false,
      default: 'Copied to clipboard',
    },

    timeout: {
      type: Number,
      required: false,
      default: 2000,
    },

    copyContent: {
      type: String,
      required: true,
      default: '',
    },

    showText: {
      type: Boolean,
      required: false,
      default: true,
    },

    showToastAlert: {
      type: Boolean,
      required: false,
      default: false,
    },

    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },

    copyTextLabelClass: {
      type: String,
      required: false,
      default: 'vd-a',
    },

    copyTextContentClass: {
      type: String,
      required: false,
      default: 'vd-h5',
    },

    showLabelAsTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltipId: {
      type: String,
      required: false,
      default: 'clipboardCopyTooltip',
    },

    showAsButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    buttonClass: {
      type: String,
      required: false,
      default: '',
    },

    copyAsHtml: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      copied: false,
      copyTimeOut: null,
    }
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'common/isMobileDevice',
    }),
  },

  methods: {
    ...mapActions({
      displayToastAlert: 'common/displayToastAlert',
    }),

    copyToClipboard() {
      const vm = this

      if (this.copyTimeOut) {
        clearTimeout(this.copyTimeOut)
      }

      if (this.copyAsHtml) {
        // Create a div element to append the HTML.
        const element = document.createElement('div')
        const selection = window.getSelection()
        const copyFrom = document.createRange()

        // Append the HTML to above div.
        element.innerHTML = this.copyContent
        document.body.appendChild(element)
        copyFrom.selectNodeContents(element)
        selection.removeAllRanges()
        selection.addRange(copyFrom)
        document.execCommand('copy')

        // Clean up the div element.
        element.parentNode.removeChild(element)
        this.copied = true
      } else {
        this.copied = CopyToClipboard(this.copyContent)
      }

      if (this.copied) {
        this.copyTimeOut = setTimeout(() => {
          vm.copied = false
        }, vm.timeout)
      }

      if (this.showToastAlert) {
        this.displayToastAlert({
          title: this.successMessage,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/clipboard-copy';
</style>
