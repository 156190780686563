export default Object.freeze({
  // TODO: Remove this and use the API when ready.
  policies: [
    {
      id: 1,
      key: 'keep',
      name: 'Leave on the server. Assets to be used in ongoing projects.',
    },
    {
      id: 2,
      key: 'long-term-archive',
      name: 'Save to archive. Assets to be kept for future projects.',
    },
    {
      id: 3,
      key: 'delete-when-archived',
      name:
        'Hold for a minimum of three months after project archive then schedule for deletion.',
    },
    {
      id: 4,
      key: 'unsure',
      name:
        'Unsure if assets will be required in future(send client information).',
    },
    {
      id: 5,
      key: 'follow-policy',
      name: 'Follow the provided policy agreement.',
    },
  ],
})
