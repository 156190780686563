<template>
  <div
    :class="[
      isMediumScreen
        ? 'vms-project-summary__container--small'
        : 'vms-project-summary__container',
      isMobileScreen ? '' : 'vms-content__container',
    ]"
  >
    <default-loader
      v-if="isProjectLoading || editProjectLoading"
    ></default-loader>

    <div
      v-else
      :class="[
        'vms-project-summary__content',
        isMobileScreen ? 'vms-project-summary__content--mobile' : '',
      ]"
    >
      <div :class="[!isMobileScreen ? 'vms-project-summary__wrapper' : '']">
        <div
          :class="[
            'vd-padding-left-0',
            isMobileScreen ? 'col-12' : 'col-4 vd-margin-bottom-40',
          ]"
        >
          <project-thumbnail
            v-if="canUpdateContacts"
            :class="!isMobileScreen ? 'vd-margin-right-40' : ''"
          ></project-thumbnail>

          <h4
            v-if="isMobileScreen"
            :class="[
              'vd-h4 vd-dark-grey',
              isEditMode ? 'vd-margin-bottom-large' : 'vd-margin-bottom-medium',
            ]"
          >
            GENERAL
          </h4>

          <new-project-form-info-section
            v-if="!canUpdateContacts"
          ></new-project-form-info-section>

          <project-info-section
            v-else
            @init:default-payload="initDefaultPayload"
            @update:changed-payload="updateChangedPayload"
          ></project-info-section>

          <project-contacts
            :contact-header-container-class="contactHeaderContainerClass"
            :can-update-contacts="canUpdateContacts"
            :contacts-label-text="
              !canUpdateContacts ? 'Project Contact' : 'Contacts'
            "
            :contacts-label-class="
              !canUpdateContacts ? 'vd-light-grey' : 'vd-light-blue'
            "
          ></project-contacts>
        </div>

        <project-brief-section
          :class="['vd-padding-left-0', isMobileScreen ? 'col-12' : 'col-8']"
          :display-overlay="!canUpdateContacts"
          @init:default-payload="initDefaultPayload"
          @update:changed-payload="updateChangedPayload"
        ></project-brief-section>
      </div>
    </div>

    <contact-form-modal v-if="showContactForm"></contact-form-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isNil, isEmpty, merge, isEqual } from 'lodash'
import ProjectThumbnail from '@views/project/summary/components/project-thumbnail'
import ProjectInfoSection from '@views/project/summary/components/form-sections/project-info-section'
import ProjectBriefSection from '@views/project/summary/components/form-sections/project-brief-section'
import ProjectContacts from '@views/project/summary/components/project-contacts/project-contacts'
import NewProjectFormInfoSection from '@views/projects/new-project/components/forms/new-project-form'
import DefaultLoader from '@components/loaders/default-loader'
import SweetAlert from '@plugins/sweet-alert'
import ContactFormModal from '@components/contact-form-modal/contact-form-modal'

export default {
  components: {
    ProjectThumbnail,
    ProjectInfoSection,
    ProjectBriefSection,
    ProjectContacts,
    NewProjectFormInfoSection,
    DefaultLoader,
    ContactFormModal,
  },

  data() {
    return {
      defaultPayload: {},
      changedPayload: {},
    }
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      productTypesLoading: 'project/productTypesLoading',
      producersListLoading: 'user/producersListLoading',
      accountManagersListLoading: 'user/accountManagersListLoading',
      productTypesList: 'project/productTypesList',
      producers: 'user/producers',
      accountManagers: 'user/accountManagers',
      editProjectSummaryFormPayload: 'project/editProjectSummaryFormPayload',
      isEditMode: 'project/isEditMode',
      editProjectLoading: 'project/editProjectLoading',
      isProjectInfoFormInvalid: 'project/isProjectInfoFormInvalid',
      isProjectBriefFormInvalid: 'project/isProjectBriefFormInvalid',
      isMobileScreen: 'common/isMobileScreen',
      targetAudienceTypesLoading: 'project/targetAudienceTypesLoading',
      targetAudienceTypes: 'project/targetAudienceTypes',
      showContactForm: 'user/showContactForm',
      isMediumScreen: 'common/isMediumScreen',
    }),

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    hasProjectDetails() {
      return !isNil(this.projectDetails.id) && this.projectDetails.id !== 'new'
    },

    canUpdateContacts() {
      return this.hasProjectDetails && !this.isProjectReopen
    },

    contactHeaderContainerClass() {
      return this.canUpdateContacts
        ? 'vms-project-summary__contacts--title vd-position-relative vd-h4 vd-black vd-padding-bottom-small vd-border-light-grey'
        : 'vd-h5 vd-margin-top-30'
    },

    isProjectLoading() {
      return (
        this.productTypesLoading ||
        this.producersListLoading ||
        this.accountManagersListLoading ||
        this.targetAudienceTypesLoading
      )
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isEditMode) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> You have unsaved changes. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateProjectSummaryEditMode(false)
          next()
        }
      })
    } else {
      next()
    }
  },

  mounted() {
    if (
      isEmpty(this.productTypesList) ||
      isEmpty(this.producers) ||
      isEmpty(this.accountManagers)
    ) {
      this.getProductTypesList()
      this.getProducersList()
      this.getAccountManagers()
    }

    if (isEmpty(this.targetAudienceTypes)) {
      this.getTargetAudienceTypes()
    }

    // Show project health by default when URL query exist (project_health=true)
    if (this.$route.query.project_health === 'true') {
      this.toggleProjectHealthModalShow()
    }
  },

  methods: {
    ...mapActions({
      getProductTypesList: 'project/getProductTypesList',
      getProducersList: 'user/getProducersList',
      getAccountManagers: 'user/getAccountManagers',
      updateProjectSummaryEditMode: 'project/updateProjectSummaryEditMode',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      getTargetAudienceTypes: 'project/getTargetAudienceTypes',
      toggleProjectHealthModalShow:
        'project/project-health/toggleProjectHealthModalShow',
    }),

    initDefaultPayload(val) {
      this.defaultPayload = merge(this.defaultPayload, val)
    },

    updateChangedPayload(val) {
      this.changedPayload = merge(this.changedPayload, val)
      this.updateFormSaveBtnDisabled(
        isEqual(this.defaultPayload, this.changedPayload) ||
          this.isProjectInfoFormInvalid ||
          this.isProjectBriefFormInvalid
      )
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/project-summary';
@import '@styles/plugins/vuetify';
</style>
