<template>
  <text-popover
    :tooltip-id="tooltipId"
    tooltip-trigger="hover"
    :tooltip-placement="tooltipPlacement"
    :use-label-slot="true"
    :close-on-click-only="false"
  >
    <div :id="tooltipId" slot="popover-label">
      <user-profile
        :user="selectedUser"
        :custom-image-class="labelAvatarImageClass"
        :custom-avatar-class="labelAvatarClass"
        :custom-avatar-font-size="labelAvatarFontSize"
      ></user-profile>
    </div>

    <div slot="popover-body" class="vd-padding-small vms-staff-popover--body">
      <div class="row no-gutters">
        <div class="vd-margin-right-medium">
          <user-profile
            :user="selectedUser"
            :custom-avatar-class="popoverAvatarClass"
            :custom-image-class="popoverAvatarImageClass"
            :custom-avatar-font-size="80"
          ></user-profile>
        </div>

        <div>
          <div class="vd-h3">{{ fullName }}</div>
          <div>{{ selectedUser.staff_title }}</div>
          <div v-if="selectedUser.state">{{ selectedUser.state.name }}</div>
        </div>
      </div>

      <div
        v-if="!isMobileDevice"
        class="vms-staff-popover--profile__info--divider vd-border-dark-grey vd-margin-top-medium"
      >
        <div
          class="vd-position-relative vd-text-align-center vd-padding-top-small"
        >
          <div v-if="selectedUser.email" class="vd-display-inline-block">
            <clipboard-copy
              container-class="vd-margin-small"
              custom-icon="vd-icon-mail vms-staff-popover--profile__info--icon"
              :copy-content="selectedUser.email"
              :show-text="false"
              :show-toast-alert="true"
              :success-message="clipboardCopySuccessMessage('email')"
            ></clipboard-copy>
          </div>

          <div v-if="selectedUser.phone" class="vd-display-inline-block">
            <clipboard-copy
              container-class="vd-margin-small"
              custom-icon="vd-icon-call vms-staff-popover--profile__info--icon"
              :copy-content="selectedUser.phone"
              :show-text="false"
              :show-toast-alert="true"
              :success-message="clipboardCopySuccessMessage('phone')"
            ></clipboard-copy>
          </div>

          <div v-if="isEditable" class="vd-display-inline-block">
            <div
              class="vd-icon-pencil-grey-circle vd-margin-inline-small vms-staff-popover--profile__info--icon"
              @click="editUser"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </text-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import TextPopover from '@components/tooltips/text-popover'
import UserProfile from '@components/user-profile/user-profile'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'

export default {
  components: {
    TextPopover,
    UserProfile,
    ClipboardCopy,
  },

  props: {
    tooltipId: {
      type: String,
      required: true,
      default: '',
    },

    selectedUser: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    labelAvatarFontSize: {
      type: Number,
      required: false,
      default: 45,
    },

    labelAvatarImageClass: {
      type: String,
      required: false,
      default: '',
    },

    labelAvatarClass: {
      type: String,
      required: false,
      default: '',
    },

    popoverAvatarImageClass: {
      type: String,
      required: false,
      default: 'vms-staff-popover--profile__info',
    },

    popoverAvatarClass: {
      type: String,
      required: false,
      default: 'vms-staff-popover--profile__info',
    },

    tooltipPlacement: {
      type: String,
      required: false,
      default: 'right',
    },

    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'common/isMobileDevice',
    }),

    fullName() {
      return (
        this.selectedUser.fullName ||
        `${this.selectedUser.first_name} ${this.selectedUser.last_name}`
      )
    },
  },

  methods: {
    clipboardCopySuccessMessage(type) {
      return `Successfully copied the ${type} of ${this.selectedUser.first_name} to your clipboard!`
    },

    editUser() {
      this.$emit('click:edit', this.selectedUser)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/staff-popover';
</style>
