<template>
  <div
    class="vms-project-summary__contacts--search__result-hits vd-border-light-grey"
  >
    <div
      v-if="availableContacts.length <= 0"
      class="vms-project-summary__contacts--search__result-hits--options vd-border-light-grey vd-padding-10 vd-position-relative"
    >
      <span>No contacts found.</span>
    </div>

    <div
      v-for="contact in availableContacts"
      v-else
      :key="contact.user_id"
      class="vms-project-summary__contacts--search__result-hits--options vd-border-light-grey vd-padding-10 vd-position-relative"
    >
      <div class="row no-gutters" @click="handleClickContact(contact)">
        <user-profile
          :user="contact"
          class="vd-margin-right-medium"
          custom-avatar-class="vms-project-summary__contacts--search__result-hits--options__avatar"
          custom-image-class="vms-project-summary__contacts--search__result-hits--options__avatar"
        ></user-profile>

        <div>
          <div class="vd-h5 vd-dark-grey">{{ contact.full_name }}</div>
          <div class="vd-h4 vd-black">{{ contact.email }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { parseInt, filter, some } from 'lodash'
import UserProfile from '@components/user-profile/user-profile'

export default {
  components: {
    UserProfile,
  },
  props: {
    queryItems: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      contacts: 'project/contact/projectContacts',
    }),

    availableContacts() {
      return filter(this.queryItems, (contact) => {
        return !some(this.contacts, {
          id: parseInt(contact.user_id),
        })
      })
    },
  },

  methods: {
    ...mapActions({
      addNewProjectContact: 'project/contact/addNewProjectContact',
    }),

    handleClickContact(contact) {
      this.addNewProjectContact({
        projectId: this.projectDetails.id,
        includes: null,
        payload: {
          contact_id: parseInt(contact.user_id),
          office_id: this.projectDetails.office.id,
          contact_name: contact.first_name,
        },
      })

      this.$emit('update:search-off-focus')
    },
  },
}
</script>
