<template>
  <div
    class="vms-project-summary__contacts--search__result-hits vd-border-light-grey"
  >
    <div
      class="vms-project-summary__contacts--search__no-results vd-h4 vd-padding-10 vd-black"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: 'No contacts to display',
    },
  },
}
</script>
