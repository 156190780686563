var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-project-summary__overlay',
    _vm.isMobileScreen
      ? 'vms-project-summary__overlay--mobile vd-text-align-right'
      : 'vd-text-align-center' ]},[_c('div',{class:[
      'vms-project-summary__overlay--content vd-background-white',
      _vm.isMobileScreen
        ? 'vms-project-summary__overlay--content__mobile vd-padding-bottom-extra-large'
        : 'vd-padding-20' ]},[(_vm.createNewProjectLoading)?_c('default-loader'):_c('div',[(!_vm.isProjectReopen)?_c('div',[(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-margin-bottom-large vms-project-summary__overlay--icon"},[_c('img',{attrs:{"src":"/images/project/create-new-project-icon.png"}})]):_vm._e(),(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-margin-bottom-large"},[_c('p',[_vm._v(" Once you save the project you can start viewing and updating the project's details. ")])]):_vm._e(),(_vm.isMobileScreen)?_c('router-link',{staticClass:"vd-darker-grey vd-h5",attrs:{"id":"resetCreateProjectLink","to":{ name: 'project.create' }}},[_vm._v(" Restart ")]):_vm._e(),_c('div',{class:[
            _vm.isMobileScreen
              ? 'vd-margin-left-large vd-display-inline-block'
              : 'vd-margin-bottom-medium' ]},[_c('button',{staticClass:"vd-btn-medium vd-btn-blue",attrs:{"id":"saveNewProjectButton","disabled":_vm.formSaveBtnDisabled},on:{"click":function($event){return _vm.createNewProject(null)}}},[_vm._v(" Save project ")])]),(!_vm.isMobileScreen)?_c('router-link',{staticClass:"vd-darker-grey vd-h5",attrs:{"id":"resetCreateProjectLink","to":{ name: 'project.create' }}},[_vm._v(" Restart ")]):_vm._e()],1):_c('div',[(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-margin-bottom-large vms-project-summary__overlay--icon"},[_c('img',{attrs:{"src":"/images/project/create-new-project-icon.png"}})]):_vm._e(),(!_vm.isMobileScreen)?_c('div',{staticClass:"vd-margin-bottom-large"},[_c('p',[_vm._v(" Once you reopen the project you can start viewing and updating the project's details. ")])]):_vm._e(),(_vm.isMobileScreen)?_c('router-link',{staticClass:"vd-darker-grey vd-h5",attrs:{"id":"cancelReopenProjectLink","to":{
            name: 'project.details.summary',
            projectId: _vm.projectDetails.id,
          }}},[_vm._v(" Cancel ")]):_vm._e(),_c('div',{class:[
            _vm.isMobileScreen
              ? 'vd-margin-left-large vd-display-inline-block'
              : 'vd-margin-bottom-medium' ]},[_c('button',{staticClass:"vd-btn-medium vd-btn-blue",attrs:{"id":"reopenProjectButton","disabled":_vm.formSaveBtnDisabled},on:{"click":_vm.handleReopenProject}},[_vm._v(" Reopen project ")])]),(!_vm.isMobileScreen)?_c('router-link',{staticClass:"vd-darker-grey vd-h5",attrs:{"id":"cancelReopenProjectLink","to":{
            name: 'project.details.summary',
            projectId: _vm.projectDetails.id,
          }}},[_vm._v(" Cancel ")]):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }