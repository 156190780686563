<template>
  <div class="vms-text-editor">
    <label :class="[customLabelClass]">{{ label }}</label>
    <vue-editor
      v-if="!disabled"
      v-model="contentValue"
      :editor-toolbar="customToolbar"
    />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-else
      :class="[
        'vms-text-editor__custom',
        contentValue
          ? 'vd-border-radius-10 vd-border-width-1 vd-padding-small vd-padding-block-extra-small vd-border-green vd-icon-tick-green'
          : '',
      ]"
      v-html="contentValue"
    ></div>
  </div>
</template>

<style lang="scss">
@import '~vue2-editor/dist/vue2-editor.css';
@import '@styles/components/text-editor';
</style>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    content: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    customLabelClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      contentValue: cloneDeep(this.content),
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
      ],
    }
  },

  watch: {
    content() {
      this.handleContentUpdate(this.content)
    },

    contentValue(val) {
      this.handleContentUpdate(val)
    },
  },

  methods: {
    handleContentUpdate(contentValue) {
      this.contentValue = isEmpty(contentValue) ? null : contentValue
      this.$emit('update:text-editor-content', this.contentValue)
    },
  },
}
</script>
