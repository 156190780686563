<template>
  <div class="vms-input__read-only">
    <div
      :class="[
        'vd-field',
        inputRequired ? 'vd-required' : '',
        !isInputContentEmpty ? 'vd-input-filled vd-input-valid' : '',
        inputRequired && isInputContentEmpty ? 'vd-input-error' : '',
      ]"
    >
      <label>{{ labelContent }}</label>
      <text-popover
        v-if="tooltipText"
        class="vd-display-inline-block"
        tooltip-icon="vd-icon icon-circle-question"
        custom-tooltip-class="vd-h5 vd-padding-left-extra-small vd-cursor-pointer"
        :tooltip-id="`${id}Tooltip`"
        :tooltip-text="tooltipText"
      ></text-popover>

      <input
        v-if="!isMultiValue"
        :id="id"
        type="text"
        class="vd-padding-bottom-extra-small"
        :class="[inputContentClass]"
        disabled
        :value="inputContent"
      />

      <div
        v-if="isMultiValue"
        :class="[
          'vms-input__read-only__multi-value',
          inputContentClass,
          isInputContentEmpty
            ? 'vms-input__read-only__multi-value__border--neutral'
            : 'vms-input__read-only__multi-value__border--success vd-padding-right-medium',
          inputRequired && isInputContentEmpty
            ? 'vms-input__read-only__multi-value__border--error vd-padding-right-medium'
            : '',
        ]"
      >
        <span
          v-for="(input, index) in inputContent"
          :key="index"
          class="vd-display-inline-block vd-margin-right-extra-small vd-margin-bottom-extra-small vd-border-radius-50 vd-padding-left-small vd-padding-right-small vd-background-lighter-grey"
        >
          {{ input[multiSelectLabel] }}
        </span>
        <div
          :class="[
            !isInputContentEmpty ? 'vd-icon-tick-green' : '',
            'vms-input__read-only__multi-value--icon vd-position-absolute',
            'ml-auto',
          ]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { isArray, isEmpty, isNumber } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },

  props: {
    labelContent: {
      type: String,
      required: true,
    },

    inputContent: {
      type: [String, Number, Array],
      required: false,
      default: '',
    },

    id: {
      type: String,
      required: true,
    },

    inputContentClass: {
      type: String,
      required: false,
      default: '',
    },

    inputRequired: {
      type: Boolean,
      required: false,
      default: false,
    },

    multiSelectLabel: {
      type: String,
      required: false,
      default: 'name',
    },

    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    isMultiValue() {
      return isArray(this.inputContent)
    },

    isInputContentEmpty() {
      if (isNumber(this.inputContent)) {
        return Boolean(this.inputContent)
      }
      return isEmpty(this.inputContent)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/input';
</style>
