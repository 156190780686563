<template>
  <ais-instant-search
    class="vd-full-width"
    index-name="user_offices_index"
    :search-client="searchClient"
  >
    <ais-search-box>
      <input-component
        slot-scope="{ currentRefinement, refine }"
        :current-refinement="currentRefinement"
        type="text"
        name="projectSummaryAlgoliaSearch"
        label="Search"
        :is-algolia-search-type="true"
        @update:current-refinement="refine($event)"
      ></input-component>
    </ais-search-box>

    <ais-configure :filters="filters" />

    <ais-state-results>
      <div slot-scope="{ query, hits }" class="vd-position-relative">
        <no-results v-if="!hits.length" :query="query"></no-results>

        <ais-hits>
          <result-hits
            slot-scope="{ items }"
            :query-items="items"
            @update:search-off-focus="toggleShowAddContactsList"
          ></result-hits>
        </ais-hits>
      </div>
    </ais-state-results>
  </ais-instant-search>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import algoliaSearch from '@plugins/algolia'
import {
  AisInstantSearch,
  AisHits,
  AisStateResults,
  AisConfigure,
  AisSearchBox,
} from 'vue-instantsearch'
import InputComponent from '@components/form/form-input'
import NoResults from '@views/project/summary/components/project-contacts/project-contacts-no-results'
import ResultHits from '@views/project/summary/components/project-contacts/project-contacts-result-hits'

export default {
  components: {
    NoResults,
    ResultHits,
    AisInstantSearch,
    AisHits,
    AisStateResults,
    AisConfigure,
    AisSearchBox,
    InputComponent,
  },

  data() {
    return {
      searchClient: algoliaSearch,
    }
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
    }),

    filters() {
      return `offices.id:${this.projectDetails.office_id}`
    },
  },

  methods: {
    ...mapActions({
      toggleShowAddContactsList: 'project/contact/toggleShowAddContactsList',
    }),
  },
}
</script>
