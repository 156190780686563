var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-input__read-only"},[_c('div',{class:[
      'vd-field',
      _vm.inputRequired ? 'vd-required' : '',
      !_vm.isInputContentEmpty ? 'vd-input-filled vd-input-valid' : '',
      _vm.inputRequired && _vm.isInputContentEmpty ? 'vd-input-error' : '' ]},[_c('label',[_vm._v(_vm._s(_vm.labelContent))]),(_vm.tooltipText)?_c('text-popover',{staticClass:"vd-display-inline-block",attrs:{"tooltip-icon":"vd-icon icon-circle-question","custom-tooltip-class":"vd-h5 vd-padding-left-extra-small vd-cursor-pointer","tooltip-id":(_vm.id + "Tooltip"),"tooltip-text":_vm.tooltipText}}):_vm._e(),(!_vm.isMultiValue)?_c('input',{staticClass:"vd-padding-bottom-extra-small",class:[_vm.inputContentClass],attrs:{"id":_vm.id,"type":"text","disabled":""},domProps:{"value":_vm.inputContent}}):_vm._e(),(_vm.isMultiValue)?_c('div',{class:[
        'vms-input__read-only__multi-value',
        _vm.inputContentClass,
        _vm.isInputContentEmpty
          ? 'vms-input__read-only__multi-value__border--neutral'
          : 'vms-input__read-only__multi-value__border--success vd-padding-right-medium',
        _vm.inputRequired && _vm.isInputContentEmpty
          ? 'vms-input__read-only__multi-value__border--error vd-padding-right-medium'
          : '' ]},[_vm._l((_vm.inputContent),function(input,index){return _c('span',{key:index,staticClass:"vd-display-inline-block vd-margin-right-extra-small vd-margin-bottom-extra-small vd-border-radius-50 vd-padding-left-small vd-padding-right-small vd-background-lighter-grey"},[_vm._v(" "+_vm._s(input[_vm.multiSelectLabel])+" ")])}),_c('div',{class:[
          !_vm.isInputContentEmpty ? 'vd-icon-tick-green' : '',
          'vms-input__read-only__multi-value--icon vd-position-absolute',
          'ml-auto' ]})],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }