<template>
  <div>
    <project-brief-overlay v-if="displayOverlay"></project-brief-overlay>

    <div v-if="isMobileScreen ? !displayOverlay : true" class="row">
      <div
        :class="['vd-margin-bottom-small', isMobileScreen ? 'col-12' : 'col-6']"
      >
        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            id="projectSummaryProductionCoordinatorInput"
            :value="productionCoordinatorId"
            :dropdown-select-list="producers"
            :dropdown-select-config="producerDropdownConfig"
            :container-custom-classes="
              getInputContainerCustomClass('productionCoordinatorId')
            "
            placeholder="Select a Production Coordinator"
            label="Production Coordinator"
            type="dropdown-select"
            name="productionCoordinatorId"
            :required="false"
            @update:model-list-select="handleProductionCoordinatorIdUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryProductionCoordinatorInput"
            label="Production Coordinator"
            :value="projectDetails.production_coordinator.full_name"
            :required="false"
            :display-input-component="false"
          >
            <staff-popover
              v-if="projectDetails.production_coordinator.id"
              slot="input-prepend"
              class="vms-project-summary__input-prepend"
              :selected-user="projectDetails.production_coordinator"
              :tooltip-id="`production-coordinator-${projectDetails.production_coordinator.id}`"
              :label-avatar-font-size="25"
              label-avatar-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
              label-avatar-image-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
            ></staff-popover>
          </project-summary-read-only>
        </div>

        <div
          :class="[
            isMobileScreen ? 'vd-margin-bottom-60' : 'vd-margin-bottom-30',
          ]"
        >
          <project-summary-editable
            v-if="isEditMode"
            id="projectSummaryDirectorInput"
            :value="directorId"
            :dropdown-select-list="producers"
            :dropdown-select-config="producerDropdownConfig"
            :container-custom-classes="
              getInputContainerCustomClass('directorId')
            "
            placeholder="Select a Director"
            label="Director"
            type="dropdown-select"
            name="projectSummaryDirectorInput"
            :required="false"
            @update:model-list-select="handleDirectorIdUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryDirectorInput"
            label="Director"
            :value="projectDetails.director.full_name"
            :required="false"
            :display-input-component="false"
          >
            <staff-popover
              v-if="projectDetails.director.id"
              slot="input-prepend"
              class="vms-project-summary__input-prepend"
              :selected-user="projectDetails.director"
              :tooltip-id="`production-coordinator-${projectDetails.director.id}`"
              :label-avatar-font-size="25"
              label-avatar-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
              label-avatar-image-class="vms-project-summary__input-prepend--avatar vd-margin-bottom-extra-small"
            ></staff-popover>
          </project-summary-read-only>
        </div>

        <h4
          v-if="isMobileScreen"
          :class="[
            'vd-h4 vd-dark-grey',
            isEditMode ? 'vd-margin-bottom-large' : 'vd-margin-bottom-medium',
          ]"
        >
          PRODUCTION
        </h4>

        <div v-if="!displayOverlay" class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            name="projectSummaryAllocatedHours"
            :value="allocatedHours"
            label="Estimated Hours"
            type="number"
            placeholder="How many hours to complete the project?"
            :required="true"
            :container-custom-classes="
              setInputValidationClass('allocatedHours')
            "
            @update:project-form-input="
              handleUpdateProjectInputForm(
                $event,
                'allocatedHours',
                'budgeted_hours'
              )
            "
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="number"
            name="projectSummaryAllocatedHours"
            label="Estimated Hours"
            :value="allocatedHours"
            :required="true"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            id="projectStartDateInput"
            :value="projectStartDate"
            :datepicker-config="projectStartDateConfig"
            :container-custom-classes="
              setInputValidationClass('projectStartDate')
            "
            label="Project Start Date"
            type="datepicker"
            placeholder="Select the project start date"
            name="projectSummaryStartDateInput"
            :required="false"
            @update:selected-date="handleProjectStartDateUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryStartDateInput"
            label="Project Start Date"
            :value="getStartOrEndDate(projectDetails.start_date)"
            :required="false"
            :display-input-component="false"
          ></project-summary-read-only>
        </div>

        <div class="row no-gutters">
          <div
            :class="[
              'vd-margin-bottom-30',
              isMobileScreen ? 'col-12' : 'col-6',
            ]"
          >
            <div
              :class="[isMobileScreen ? '' : 'vd-padding-right-extra-small']"
            >
              <project-summary-editable
                v-if="isEditMode"
                id="projectDeadlineInput"
                :value="projectDeadline"
                :datepicker-config="projectDeadlineConfig"
                :tooltip-text="estimatedFirstDeliveryDateTooltipText"
                :container-custom-classes="
                  setInputValidationClass('projectDeadline')
                "
                label="Estimated first delivery date"
                type="datepicker"
                placeholder="Estimated first delivery date"
                name="projectSummaryDueDateInput"
                :required="false"
                @update:selected-date="handleProjectDeadlineUpdate"
              ></project-summary-editable>

              <project-summary-read-only
                v-else
                type="text"
                name="projectSummaryDueDateInput"
                label="Estimated first delivery date"
                :value="getStartOrEndDate(projectDetails.due_date)"
                :required="false"
                :display-input-component="false"
                :tooltip-text="estimatedFirstDeliveryDateTooltipText"
              ></project-summary-read-only>
            </div>
          </div>
          <div
            :class="[
              'vd-margin-bottom-30',
              isMobileScreen ? 'col-12' : 'col-6',
            ]"
          >
            <div :class="[isMobileScreen ? '' : 'vd-padding-left-extra-small']">
              <project-summary-editable
                v-if="isEditMode"
                id="projectSummaryHardDeadlineInput"
                :value="hardDeadline"
                :dropdown-select-list="hardDeadlineOptionList"
                :dropdown-select-config="hardDeadlineDropdownConfig"
                placeholder="Is it hard deadline?"
                label="Hard deadline?"
                type="dropdown-select"
                name="hardDeadline"
                :required="false"
                @update:model-list-select="handleHardDeadlineUpdate"
              ></project-summary-editable>

              <project-summary-read-only
                v-else
                type="text"
                name="projectSummaryHardDeadlineInput"
                label="Hard deadline?"
                :value="hardDeadlineLabel"
                :required="false"
                :display-input-component="false"
              ></project-summary-read-only>
            </div>
          </div>
        </div>

        <div class="vd-margin-bottom-30">
          <text-editor
            v-if="isEditMode"
            :class="['vd-field', goals ? 'vd-input-valid' : '']"
            label="Goals"
            :content="goals"
            @update:text-editor-content="handleAGoalsUpdate"
          ></text-editor>

          <project-summary-read-only
            v-else
            type="textarea"
            name="projectSummaryGoalsTextarea"
            label="Goals"
            :value="projectDetails.goals"
            :required="false"
            :render-html="true"
          ></project-summary-read-only>
        </div>
      </div>

      <div
        :class="['vd-margin-bottom-small', isMobileScreen ? 'col-12' : 'col-6']"
      >
        <h4
          v-if="isMobileScreen"
          :class="[
            'vd-h4 vd-dark-grey',
            isEditMode ? 'vd-margin-bottom-large' : 'vd-margin-bottom-medium',
          ]"
        >
          VIDEO
        </h4>

        <div v-if="canViewBudget" class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            id="projectSummaryBudgetInput"
            :value="budget"
            :dropdown-select-list="budgetsList"
            :dropdown-select-config="budgetDropdownConfig"
            :container-custom-classes="getInputContainerCustomClass('budget')"
            placeholder="Select a Budget"
            label="Budget"
            type="dropdown-select"
            name="projectSummaryBudgetInput"
            :required="false"
            @update:model-list-select="handleBudgetUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryBudgetInput"
            label="Budget"
            :value="projectDetails.budget"
            :required="false"
            :display-input-component="false"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            id="projectSummaryPrimaryPlatformInput"
            :value="primaryPlatform"
            :dropdown-select-list="platformsList"
            :dropdown-select-config="platformDropdownConfig"
            :container-custom-classes="
              getInputContainerCustomClass('primaryPlatform')
            "
            placeholder="Select a Platform"
            label="Primary Platform"
            type="dropdown-select"
            name="projectSummaryPrimaryPlatformInput"
            :required="false"
            @update:model-list-select="handlePlatformUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryPrimaryPlatformInput"
            label="Primary Platform"
            :value="projectDetails.platform"
            :required="false"
            :display-input-component="false"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            name="projectSummaryTopic"
            :value="projectDetails.topic"
            label="Topic"
            type="text"
            placeholder="Detail the main topics of the project"
            :required="false"
            :container-custom-classes="getInputContainerCustomClass('topic')"
            @update:project-form-input="
              handleUpdateProjectInputForm($event, 'topic', 'topic')
            "
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryTopic"
            label="Topic"
            :value="projectDetails.topic"
            :required="false"
          ></project-summary-read-only>
        </div>
        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="
              isEditMode && projectSummaryTargetAudienceInputType === 'text'
            "
            name="projectSummaryTargetAudience"
            :value="projectDetails.target_audience"
            label="Target Audience"
            placeholder="Please select a target audience"
            :type="projectSummaryTargetAudienceInputType"
            :required="false"
            :container-custom-classes="
              getInputContainerCustomClass('targetAudience')
            "
            @update:project-form-input="
              handleUpdateProjectInputForm(
                $event,
                'targetAudience',
                'target_audience'
              )
            "
          ></project-summary-editable>

          <project-summary-editable
            v-else-if="
              isEditMode &&
              projectSummaryTargetAudienceInputType === 'dropdown-multi-select'
            "
            name="projectSummaryTargetAudience"
            label="Target Audience"
            placeholder="Please select target audiences"
            :value="projectTargetAudienceTypes"
            :type="projectSummaryTargetAudienceInputType"
            :required="false"
            :dropdown-select-list="targetAudienceTypeOptions"
            :dropdown-select-config="{
              option_value: 'id',
              option_text: 'name',
            }"
            :container-custom-classes="
              setInputValidationClass('targetAudiences')
            "
            @update:multi-select="
              handleUpdateProjectInputForm(
                $event,
                'targetAudiences',
                'target_audiences'
              )
            "
          ></project-summary-editable>

          <project-summary-read-only
            v-else-if="
              !isEditMode && projectSummaryTargetAudienceInputType === 'text'
            "
            name="projectSummaryTargetAudience"
            label="Target Audience"
            :type="projectSummaryTargetAudienceInputType"
            :value="projectDetails.target_audience"
            :required="false"
          ></project-summary-read-only>

          <project-summary-read-only
            v-else-if="
              !isEditMode &&
              projectSummaryTargetAudienceInputType === 'dropdown-multi-select'
            "
            name="projectSummaryTargetAudience"
            label="Target Audience"
            :type="projectSummaryTargetAudienceInputType"
            :value="projectDetails.target_audience_types"
            :required="false"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            name="projectSummaryVideoExamples"
            :value="projectDetails.video_example"
            label="Video Example"
            type="text"
            placeholder="Provide links to any relevant videos"
            :required="false"
            :container-custom-classes="
              getInputContainerCustomClass('videoExample')
            "
            @update:project-form-input="
              handleUpdateProjectInputForm(
                $event,
                'videoExample',
                'video_example'
              )
            "
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryVideoExamples"
            label="Video Example"
            :value="projectDetails.video_example"
            :required="false"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            name="projectSummaryFootagePolicy"
            :value="projectFootagePolicyId"
            label="Projects raw footage policy"
            type="dropdown-select"
            :dropdown-select-list="footagePolicies"
            :dropdown-select-config="footagePolicyDropdownConfig"
            placeholder="Select footage policy"
            :required="false"
            :container-custom-classes="
              getInputContainerCustomClass('footagePolicy')
            "
            @update:model-list-select="handleFootagePolicyUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryFootagePolicy"
            label="Projects raw footage policy"
            :value="footagePolicyLabel"
            :required="false"
          ></project-summary-read-only>
        </div>

        <div v-if="canViewVideoPrivacy" class="vd-margin-bottom-30">
          <project-summary-editable
            v-if="isEditMode"
            id="projectSummaryVideoPrivacyInput"
            :value="videoPrivacy"
            :dropdown-select-list="videoPrivacyOptionList"
            :dropdown-select-config="videoPrivacyDropdownConfig"
            :container-custom-classes="setInputValidationClass('videoPrivacy')"
            placeholder="Can the videos be shared publicly?"
            label="Video Privacy"
            type="dropdown-select"
            name="videoPrivacy"
            :required="true"
            @update:model-list-select="handleVideoPrivacyUpdate"
          ></project-summary-editable>

          <project-summary-read-only
            v-else
            type="text"
            name="projectSummaryVideoPrivacyInput"
            label="Video Privacy"
            :value="videoPrivacyLabel"
            :required="true"
            :display-input-component="false"
          ></project-summary-read-only>
        </div>

        <div class="vd-margin-bottom-30 vd-margin-top-30">
          <text-editor
            v-if="isEditMode"
            :class="['vd-field', additionalNotes ? 'vd-input-valid' : '']"
            label="Additional Notes"
            :content="additionalNotes"
            @update:text-editor-content="handleAdditionalNotesUpdate"
          ></text-editor>

          <project-summary-read-only
            v-else
            type="textarea"
            name="projectSummaryAdditionalNotesTextarea"
            label="Additional Notes"
            :value="projectDetails.additional_notes"
            :required="false"
            :render-html="true"
          ></project-summary-read-only>
        </div>
      </div>
    </div>

    <div v-if="isEditMode" class="vd-text-align-right vd-margin-bottom-medium">
      <a
        id="cancelEditProjectSummaryLink"
        class="vd-margin-right-20 vd-black"
        @click="handleCancel"
      >
        Cancel
      </a>
      <button
        id="saveEditProjectSummaryButton"
        class="vd-btn-medium vd-btn-blue"
        :disabled="formSaveBtnDisabled"
        @click="handleSave"
      >
        Save
      </button>
    </div>
  </div>
</template>

<style>
@import '~flatpickr/dist/flatpickr.css';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import ProjectBriefOverlay from '@views/project/summary/components/form-sections/project-brief-overlay'
import FormMixin from '@mixins/forms-mixin'
import {
  integer,
  minValue,
  required,
  minLength,
} from 'vuelidate/lib/validators'
import SweetAlert from '@plugins/sweet-alert'
import PlatformConstants from '@configs/platforms'
import BudgetConstants from '@configs/budgets'
import VmsConstants from '@configs/vms-constants'
import FootagePolicyTypeConstants from '@configs/footage-policy-types'
import momentTz from 'moment-timezone'
import {
  find,
  isNil,
  merge,
  isEmpty,
  map,
  clone,
  assign,
  filter,
  concat,
  sortBy,
} from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import ProjectSummaryEditable from '@views/project/summary/components/form-sections/project-summary-editable'
import ProjectSummaryReadOnly from '@views/project/summary/components/form-sections/project-summary-read-only'
import TextEditor from '@components/text-editor/text-editor'
import StaffPopover from '@components/staff-popover/staff-popover'

export default {
  components: {
    ProjectBriefOverlay,
    ProjectSummaryReadOnly,
    ProjectSummaryEditable,
    TextEditor,
    StaffPopover,
  },

  mixins: [FiltersMixin, FormMixin],

  props: {
    displayOverlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      productionCoordinatorId: null,
      directorId: null,
      allocatedHours: null,
      projectStartDate: null,
      projectDeadline: null,
      budget: '',
      goals: '',
      primaryPlatform: '',
      topic: '',
      targetAudience: '',
      targetAudiences: [],
      videoExample: '',
      additionalNotes: '',
      defaultPayload: {},
      videoPrivacy: VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PRIVATE,
      footagePolicy: null,
      hardDeadline: null,
    }
  },

  validations: {
    productionCoordinatorId: {
      integer,
    },

    directorId: {
      integer,
    },

    projectStartDate: {
      minLength: minLength(0),
    },

    projectDeadline: {
      minLength: minLength(0),
    },

    targetAudiences: {
      minLength: minLength(0),
    },

    videoPrivacy: {
      required,
      minLength: minLength(1),
    },

    allocatedHours: {
      required,
      integer,
      minLength: minValue(1),
    },
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      isEditMode: 'project/isEditMode',
      isProjectInfoFormInvalid: 'project/isProjectInfoFormInvalid',
      isProjectBriefFormInvalid: 'project/isProjectBriefFormInvalid',
      producers: 'user/producers',
      editProjectSummaryFormPayload: 'project/editProjectSummaryFormPayload',
      canViewBudget: 'project/canViewBudget',
      formSaveBtnDisabled: 'project/formSaveBtnDisabled',
      isMobileScreen: 'common/isMobileScreen',
      activeTargetAudienceTypes: 'project/targetAudienceTypes',
    }),

    projectSummaryTargetAudienceInputType() {
      // For data backward compatability, we will use 'text' input type for projects that
      // has string type on the other hand 'dropdown-multi-select' for projects that has
      // multiple target audience type.
      if (isEmpty(this.projectDetails.target_audience)) {
        return 'dropdown-multi-select'
      }
      return 'text'
    },

    /**
     * Get project's target audience types
     *
     * @return {Array} return a list of ids.
     */
    projectTargetAudienceTypes() {
      return map(this.projectDetails.target_audience_types, 'id')
    },

    /**
     * Get available target audience options.
     *
     * @return {Array} returns combination of inactive-selected and active target audiences types.
     */
    targetAudienceTypeOptions() {
      const inactiveTargetAudienceTypes = filter(
        this.projectDetails.target_audience_types,
        ['is_active', 0]
      )
      return concat(this.activeTargetAudienceTypes, inactiveTargetAudienceTypes)
    },

    canViewVideoPrivacy() {
      return !isNil(this.projectDetails.id) && !this.isProjectReopen
    },

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },

    budgetsList() {
      return BudgetConstants.budgets
    },

    videoPrivacyOptionList() {
      return [
        {
          value: VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PRIVATE,
          text: 'Private',
        },
        {
          value: VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PUBLIC,
          text: 'Public',
        },
      ]
    },

    hardDeadlineOptionList() {
      return [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ]
    },

    videoPrivacyLabel() {
      return this.projectDetails.video_privacy ===
        VmsConstants.videoPrivacyOptions.VIDEO_PRIVACY_PUBLIC
        ? 'Public'
        : 'Private'
    },

    hardDeadlineLabel() {
      if (this.projectDetails.is_hard_deadline === null) {
        return ''
      }

      return this.projectDetails.is_hard_deadline === 0 ? 'No' : 'Yes'
    },

    platformsList() {
      return PlatformConstants.platforms
    },

    projectStartDateConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate: !this.projectDetails.start_date ? 'today' : null,
      }
    },

    projectDeadlineConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate: !this.projectDetails.due_date ? 'today' : null,
      }
    },

    producerDropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'full_name',
      }
    },

    budgetDropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'value',
      }
    },

    videoPrivacyDropdownConfig() {
      return {
        option_value: 'value',
        option_text: 'text',
      }
    },

    hardDeadlineDropdownConfig() {
      return {
        option_value: 'value',
        option_text: 'text',
      }
    },

    platformDropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'value',
      }
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    changedPayload() {
      const formPayload = this.editProjectSummaryFormPayload || {}
      return {
        productionCoordinatorId: this.productionCoordinatorId,
        directorId: this.directorId,
        allocatedHours: this.allocatedHours,
        projectStartDate: this.projectStartDate,
        projectDeadline: this.projectDeadline,
        budget: this.budget,
        goals: this.goals,
        primaryPlatform: this.primaryPlatform,
        topic: this.topic,
        targetAudience: this.targetAudience,
        targetAudiences: (formPayload.target_audiences
          ? sortBy(formPayload.target_audiences)
          : []
        ).join(','),
        videoExample: this.videoExample,
        additionalNotes: this.additionalNotes,
        videoPrivacy: this.videoPrivacy,
        footagePolicy: this.footagePolicy,
        hardDeadline: this.hardDeadline,
      }
    },

    projectFootagePolicyId() {
      if (this.projectDetails.footage_policy_type_id) {
        return this.projectDetails.footage_policy_type_id
      }

      if (this.projectDetails.office.company.has_footage_policy_document) {
        return find(this.footagePolicies, {
          key: 'follow-policy',
        }).id
      }

      return null
    },

    footagePolicyDropdownConfig() {
      return {
        option_value: 'id',
        option_text: 'name',
      }
    },

    footagePolicies() {
      if (this.projectDetails.office.company.has_footage_policy_document) {
        return FootagePolicyTypeConstants.policies
      }

      return filter(
        FootagePolicyTypeConstants.policies,
        (policy) => policy.key !== 'follow-policy'
      )
    },

    footagePolicyLabel() {
      const selectedFootagePolicy = find(this.footagePolicies, {
        id: this.projectFootagePolicyId,
      })
      return selectedFootagePolicy ? selectedFootagePolicy.name : ''
    },

    estimatedFirstDeliveryDateTooltipText() {
      return this.projectDetails.is_manual_deadline
        ? ''
        : 'This field is auto-filled based on the first edit event. However, if a date is manually selected here, the automation will be disabled and the date can only be changed manually thereafter.'
    },
  },

  watch: {
    isFormInvalid() {
      this.updateProjectBriefFormInvalid(this.isFormInvalid)
    },

    changedPayload(val) {
      this.$emit('update:changed-payload', val)
    },
  },

  mounted() {
    this.initProjectBriefFormData()
    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      updateEditProjectSummaryFormPayload:
        'project/updateEditProjectSummaryFormPayload',
      updateProjectSummaryEditMode: 'project/updateProjectSummaryEditMode',
      updateProjectSummary: 'project/updateProjectSummary',
      updateProjectBriefFormInvalid: 'project/updateProjectBriefFormInvalid',
      setEditProjectSummaryFormPayload:
        'project/setEditProjectSummaryFormPayload',
    }),

    getStartOrEndDate(timestamp) {
      return timestamp && !this.isProjectReopen
        ? this.$options.filters.unixToFormat(timestamp, 'DD MMM YYYY')
        : null
    },

    initProjectBriefFormData() {
      this.updateProjectBriefFormInvalid(this.isFormInvalid)

      const selectedBudget = find(this.budgetsList, {
        value: this.projectDetails.budget,
      })

      const selectedPlatform = find(this.platformsList, {
        value: this.projectDetails.platform,
      })

      const existingProductionCoordinator = find(this.producers, {
        id: this.projectDetails.production_coordinator_id,
      })

      const existingDirector = find(this.producers, {
        id: this.projectDetails.director_id,
      })

      this.productionCoordinatorId = existingProductionCoordinator
        ? existingProductionCoordinator.id
        : null
      this.directorId = existingDirector ? existingDirector.id : null

      // Round to nearest whole number if budgeted_hours exist, otherwise, set as null
      this.allocatedHours = this.projectDetails.budgeted_hours
        ? this.projectDetails.budgeted_hours.toFixed()
        : null

      this.projectStartDate = this.getStartOrEndDate(
        this.projectDetails.start_date
      )
      this.projectDeadline = this.getStartOrEndDate(
        this.projectDetails.due_date
      )
      this.budget = !isNil(selectedBudget) ? selectedBudget.id : null
      this.goals = this.projectDetails.goals
      this.primaryPlatform = !isNil(selectedPlatform)
        ? selectedPlatform.id
        : null
      this.topic = this.projectDetails.topic
      this.targetAudience = this.projectDetails.target_audience
      this.videoExample = this.projectDetails.video_example
      this.additionalNotes = this.projectDetails.additional_notes
      this.videoPrivacy = this.projectDetails.video_privacy
      this.footagePolicy = this.projectDetails.footage_policy_type_id
      this.hardDeadline = this.projectDetails.is_hard_deadline
    },

    getInputContainerCustomClass(inputName) {
      return this[inputName] ? ['vd-input-valid'] : null
    },

    handleProductionCoordinatorIdUpdate(productionCoordinatorId) {
      this.productionCoordinatorId = productionCoordinatorId
    },

    handleDirectorIdUpdate(directorId) {
      this.directorId = directorId
    },

    handlePlatformUpdate(platformId) {
      this.primaryPlatform = platformId
    },

    handleBudgetUpdate(budgetId) {
      this.budget = budgetId
    },

    handleVideoPrivacyUpdate(privacy) {
      this.videoPrivacy = privacy
      this.handleInputUpdate(privacy, 'videoPrivacy')
    },

    handleHardDeadlineUpdate(isHardDeadline) {
      this.hardDeadline = isHardDeadline
      this.handleInputUpdate(isHardDeadline, 'hardDeadline')
    },

    handleAdditionalNotesUpdate(additionalNotes) {
      this.additionalNotes = additionalNotes
    },

    handleAGoalsUpdate(goals) {
      this.goals = goals
    },

    handleProjectDeadlineUpdate(date) {
      this.projectDeadline = date
      this.handleInputUpdate(date, 'projectDeadline')
    },

    handleProjectStartDateUpdate(date) {
      this.projectStartDate = date
      this.handleInputUpdate(date, 'projectStartDate')
    },

    handleFootagePolicyUpdate(footagePolicyId) {
      this.footagePolicy = footagePolicyId
    },

    handleUpdateProjectInputForm(inputValue, inputName, payloadName) {
      const payload = {}
      payload[payloadName] = inputValue

      this.handleInputUpdate(inputValue, inputName)

      const currentPayload = clone(this.editProjectSummaryFormPayload)
      this.setEditProjectSummaryFormPayload(assign(currentPayload, payload))
    },

    handleCancel() {
      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateProjectSummaryEditMode(false)
          this.initProjectBriefFormData()
        }
      })
    },

    handleSave() {
      if (!this.isFormInvalid) {
        this.updateProjectSummaryEditMode(false)

        const selectedBudget = find(this.budgetsList, {
          id: this.budget,
        })

        const selectedPlatform = find(this.platformsList, {
          id: this.primaryPlatform,
        })

        const payload = merge(this.editProjectSummaryFormPayload, {
          production_coordinator_id: this.productionCoordinatorId,
          director_id: this.directorId,
          start_date: momentTz(this.projectStartDate).unix(),
          due_date: momentTz(this.projectDeadline).unix(),
          budget: !isNil(selectedBudget) ? selectedBudget.value : '',
          platform: !isNil(selectedPlatform) ? selectedPlatform.value : '',
          goals: this.goals,
          additional_notes: this.additionalNotes,
          video_privacy: this.videoPrivacy,
          budgeted_hours: this.allocatedHours,
          footage_policy_type_id: this.footagePolicy,
          is_hard_deadline: this.hardDeadline,
        })

        this.updateEditProjectSummaryFormPayload(payload)

        this.updateProjectSummary({
          projectId: this.projectDetails.id,
          includes: ApiCustomIncludes.projectDetails,
        })
      }
    },

    initDefaultPayload() {
      this.defaultPayload = {
        productionCoordinatorId: this.productionCoordinatorId,
        directorId: this.directorId,
        allocatedHours: this.allocatedHours,
        projectStartDate: this.projectStartDate,
        projectDeadline: this.projectDeadline,
        budget: this.budget,
        goals: this.goals,
        primaryPlatform: this.primaryPlatform,
        topic: this.topic,
        targetAudience: this.targetAudience,
        targetAudiences: sortBy(this.projectTargetAudienceTypes).join(','),
        videoExample: this.videoExample,
        additionalNotes: this.additionalNotes,
        videoPrivacy: this.videoPrivacy,
        footagePolicy: this.footagePolicy,
        hardDeadline: this.hardDeadline,
      }

      this.$emit('init:default-payload', this.defaultPayload)
    },
  },
}
</script>
