<template>
  <div class="vms-project-summary__content--form vd-position-relative">
    <div>
      <div>
        <div v-if="type === 'textarea'">
          <div :class="['vd-field', value ? 'vd-input-valid' : '']">
            <label>{{ label }}</label>

            <textarea v-if="!renderHtml || !value" v-model="value" disabled />
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-else
              class="vms-project-summary__content--rendered vd-border-radius-10 vd-border-width-1 vd-padding-small vd-padding-block-extra-small vd-border-green vd-icon-tick-green"
              v-html="value"
            ></div>
          </div>
        </div>

        <div v-else class="vd-position-relative">
          <slot name="input-prepend"></slot>

          <input-read-only
            :id="name"
            :label-content="label"
            :input-content="value"
            :input-required="required"
            :input-content-class="inputContentClass"
            :tooltip-text="tooltipText"
          ></input-read-only>
        </div>
      </div>

      <div
        v-if="!isEditMode && isEditable"
        :class="[
          'projectDetailsFormInputIconDiv vms-project-summary__content--form__edit-icon',
          hasValue ? 'vms-project-summary__content--form__edit-filled' : '',
          inputEditIcon,
        ]"
        @click="updateProjectSummaryEditMode(true)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputReadOnly from '@components/form/input-read-only'
import FormMixin from '@mixins/forms-mixin'
import { isEmpty } from 'lodash'

export default {
  components: {
    InputReadOnly,
  },

  mixins: [FormMixin],

  props: {
    type: {
      type: String,
      required: true,
      default: 'text',
    },

    name: {
      type: String,
      required: true,
      default: '',
    },

    label: {
      type: String,
      required: true,
      default: '',
    },

    value: {
      type: [String, Number, Array],
      required: false,
      default: '',
    },

    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    inputEditIcon: {
      type: String,
      required: false,
      default: 'vd-icon-pencil-circle',
    },

    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },

    renderHtml: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      isEditMode: 'project/isEditMode',
      editProjectSummaryFormPayload: 'project/editProjectSummaryFormPayload',
    }),

    inputContentClass() {
      let classList =
        !this.isEditable && this.isEditMode
          ? 'vms-project-summary__content--form__edit-input__read-only'
          : 'vms-project-summary__content--form__edit-input'

      if (this.$slots['input-prepend']) {
        classList += ' vd-padding-left-40'
      }

      return classList
    },

    hasValue() {
      return !isEmpty(this.value)
    },
  },

  methods: {
    ...mapActions({
      updateProjectSummaryEditMode: 'project/updateProjectSummaryEditMode',
    }),

    handleFormInputUpdate(inputValue) {
      this.$emit('update:project-form-input', inputValue)
    },
  },
}
</script>
