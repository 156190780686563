<template>
  <div
    :class="[
      'vms-project-summary__overlay',
      isMobileScreen
        ? 'vms-project-summary__overlay--mobile vd-text-align-right'
        : 'vd-text-align-center',
    ]"
  >
    <div
      :class="[
        'vms-project-summary__overlay--content vd-background-white',
        isMobileScreen
          ? 'vms-project-summary__overlay--content__mobile vd-padding-bottom-extra-large'
          : 'vd-padding-20',
      ]"
    >
      <default-loader v-if="createNewProjectLoading"></default-loader>

      <div v-else>
        <div v-if="!isProjectReopen">
          <div
            v-if="!isMobileScreen"
            class="vd-margin-bottom-large vms-project-summary__overlay--icon"
          >
            <img src="/images/project/create-new-project-icon.png" />
          </div>

          <div v-if="!isMobileScreen" class="vd-margin-bottom-large">
            <p>
              Once you save the project you can start viewing and updating the
              project's details.
            </p>
          </div>

          <router-link
            v-if="isMobileScreen"
            id="resetCreateProjectLink"
            :to="{ name: 'project.create' }"
            class="vd-darker-grey vd-h5"
          >
            Restart
          </router-link>

          <div
            :class="[
              isMobileScreen
                ? 'vd-margin-left-large vd-display-inline-block'
                : 'vd-margin-bottom-medium',
            ]"
          >
            <button
              id="saveNewProjectButton"
              class="vd-btn-medium vd-btn-blue"
              :disabled="formSaveBtnDisabled"
              @click="createNewProject(null)"
            >
              Save project
            </button>
          </div>

          <router-link
            v-if="!isMobileScreen"
            id="resetCreateProjectLink"
            :to="{ name: 'project.create' }"
            class="vd-darker-grey vd-h5"
          >
            Restart
          </router-link>
        </div>

        <div v-else>
          <div
            v-if="!isMobileScreen"
            class="vd-margin-bottom-large vms-project-summary__overlay--icon"
          >
            <img src="/images/project/create-new-project-icon.png" />
          </div>

          <div v-if="!isMobileScreen" class="vd-margin-bottom-large">
            <p>
              Once you reopen the project you can start viewing and updating the
              project's details.
            </p>
          </div>

          <router-link
            v-if="isMobileScreen"
            id="cancelReopenProjectLink"
            :to="{
              name: 'project.details.summary',
              projectId: projectDetails.id,
            }"
            class="vd-darker-grey vd-h5"
          >
            Cancel
          </router-link>

          <div
            :class="[
              isMobileScreen
                ? 'vd-margin-left-large vd-display-inline-block'
                : 'vd-margin-bottom-medium',
            ]"
          >
            <button
              id="reopenProjectButton"
              class="vd-btn-medium vd-btn-blue"
              :disabled="formSaveBtnDisabled"
              @click="handleReopenProject"
            >
              Reopen project
            </button>
          </div>
          <router-link
            v-if="!isMobileScreen"
            id="cancelReopenProjectLink"
            :to="{
              name: 'project.details.summary',
              projectId: projectDetails.id,
            }"
            class="vd-darker-grey vd-h5"
          >
            Cancel
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultLoader from '@components/loaders/default-loader'

export default {
  components: {
    DefaultLoader,
  },

  computed: {
    ...mapGetters({
      formSaveBtnDisabled: 'project/formSaveBtnDisabled',
      createNewProjectLoading: 'project/createNewProjectLoading',
      projectDetails: 'project/projectDetails',
      newProjectFormPayload: 'project/newProjectFormPayload',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isProjectReopen() {
      return this.$route.name === 'project.details.reopen'
    },
  },

  methods: {
    ...mapActions({
      createNewProject: 'project/createNewProject',
      reopenProject: 'project/reopenProject',
    }),

    handleReopenProject() {
      this.reopenProject({
        projectId: this.projectDetails.id,
        includes: null,
        payload: {
          project_name: this.newProjectFormPayload.name,
          account_manager_id: this.newProjectFormPayload.account_manager_id,
          producer_id: this.newProjectFormPayload.producer_id,
          video_privacy: this.newProjectFormPayload.video_privacy,
        },
      })
    },
  },
}
</script>
