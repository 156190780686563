<template>
  <div>
    <input-component
      v-if="isInputFormType"
      :type="type"
      :name="name"
      :label="label"
      :value="value"
      :required="required"
      :placeholder="placeholder"
      :container-custom-classes="containerCustomClasses"
      @formInput="handleFormInputUpdate($event)"
    ></input-component>

    <div v-if="isDropdownSelectType">
      <div
        :class="[
          'vd-field',
          required ? 'vd-required' : '',
          ...containerCustomClasses,
        ]"
      >
        <label>{{ label }}</label>
        <text-popover
          v-if="tooltipText"
          class="vd-display-inline-block"
          tooltip-icon="vd-icon icon-circle-question"
          custom-tooltip-class="vd-h5 vd-padding-left-extra-small vd-cursor-pointer"
          :tooltip-id="`${id}Tooltip`"
          :tooltip-text="tooltipText"
        ></text-popover>

        <model-list-select
          :id="id"
          v-model="inputData"
          :name="name"
          :list="dropdownSelectList"
          :option-value="dropdownSelectConfig.option_value"
          :option-text="dropdownSelectConfig.option_text"
          :placeholder="placeholder"
          persistent-placeholder
        ></model-list-select>
      </div>
    </div>

    <div v-if="isDropdownMultiSelectType" data-app>
      <v-autocomplete
        :id="id"
        v-model="inputData"
        :class="['vd-field', ...containerCustomClasses]"
        chips
        clearable
        deletable-chips
        multiple
        small-chips
        persistent-placeholder
        no-data-text="No results to display"
        :placeholder="placeholder"
        :name="name"
        :label="label"
        :item-text="dropdownSelectConfig.option_text"
        :item-value="dropdownSelectConfig.option_value"
        :menu-props="{ offsetY: true }"
        :hide-selected="true"
        :items="dropdownSelectList"
      ></v-autocomplete>
    </div>

    <div v-if="isDatepickerType">
      <div :class="['vd-field', ...containerCustomClasses]">
        <label>{{ label }}</label>
        <text-popover
          v-if="tooltipText"
          class="vd-display-inline-block"
          tooltip-icon="vd-icon icon-circle-question"
          custom-tooltip-class="vd-h5 vd-padding-left-extra-small vd-cursor-pointer"
          :tooltip-id="`${id}Tooltip`"
          :tooltip-text="tooltipText"
        ></text-popover>

        <flat-pickr
          :id="id"
          v-model="inputData"
          :config="datepickerConfig"
          :placeholder="placeholder"
          :name="name"
        >
        </flat-pickr>
      </div>
    </div>

    <div v-if="isTextAreaType">
      <div :class="['vd-field', inputData ? 'vd-input-valid' : '']">
        <label>{{ label }}</label>

        <textarea
          :id="id"
          v-model="inputData"
          class="vms-input__textarea vd-border-light-grey vd-border-radius-6 vd-padding-medium"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import { ModelListSelect } from 'vue-search-select'
import FlatPickr from 'vue-flatpickr-component'
import { cloneDeep, isEmpty, isString } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    InputComponent,
    ModelListSelect,
    FlatPickr,
    TextPopover,
  },

  mixins: [FormMixin],

  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: true,
      default: 'text',
    },

    name: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: true,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    value: {
      type: [String, Number, Array],
      required: false,
      default: '',
    },

    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    containerCustomClasses: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    dropdownSelectList: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    datepickerConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          enableTime: false,
          allowInput: true,
          altFormat: 'd M Y',
          dateFormat: 'd M Y',
          altInput: true,
          disableMobile: 'true',
          minDate: 'today',
        }
      },
    },

    dropdownSelectConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          option_value: 'id',
          option_text: '',
        }
      },
    },

    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      inputData: null,
    }
  },

  computed: {
    isDropdownSelectType() {
      return this.type === 'dropdown-select'
    },

    isDropdownMultiSelectType() {
      return this.type === 'dropdown-multi-select'
    },

    isInputFormType() {
      return this.type === 'text' || this.type === 'number'
    },

    isTextAreaType() {
      return this.type === 'textarea'
    },

    isDatepickerType() {
      return this.type === 'datepicker'
    },
  },

  watch: {
    inputData() {
      if (isEmpty(this.inputData) && isString(this.inputData)) {
        this.inputData = null
      }

      if (this.isDropdownSelectType) {
        this.handleModelListSelecteUpdate()
      }

      if (this.isDropdownMultiSelectType) {
        this.handleMultiSelectUpdate()
      }

      if (this.isTextAreaType) {
        this.handleTextAreaUpdate()
      }

      if (this.isDatepickerType) {
        this.handleDatepickerUpdate()
      }
    },
  },

  mounted() {
    this.inputData = cloneDeep(this.value)
  },

  methods: {
    handleModelListSelecteUpdate() {
      this.$emit('update:model-list-select', this.inputData)
    },

    handleFormInputUpdate(inputValue) {
      this.$emit('update:project-form-input', inputValue)
    },

    handleTextAreaUpdate() {
      this.$emit('update:textarea', this.inputData)
    },

    handleDatepickerUpdate() {
      this.$emit('update:selected-date', this.inputData)
    },

    handleMultiSelectUpdate() {
      this.$emit('update:multi-select', this.inputData)
    },
  },
}
</script>
