var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.isMediumScreen
      ? 'vms-project-summary__container--small'
      : 'vms-project-summary__container',
    _vm.isMobileScreen ? '' : 'vms-content__container' ]},[(_vm.isProjectLoading || _vm.editProjectLoading)?_c('default-loader'):_c('div',{class:[
      'vms-project-summary__content',
      _vm.isMobileScreen ? 'vms-project-summary__content--mobile' : '' ]},[_c('div',{class:[!_vm.isMobileScreen ? 'vms-project-summary__wrapper' : '']},[_c('div',{class:[
          'vd-padding-left-0',
          _vm.isMobileScreen ? 'col-12' : 'col-4 vd-margin-bottom-40' ]},[(_vm.canUpdateContacts)?_c('project-thumbnail',{class:!_vm.isMobileScreen ? 'vd-margin-right-40' : ''}):_vm._e(),(_vm.isMobileScreen)?_c('h4',{class:[
            'vd-h4 vd-dark-grey',
            _vm.isEditMode ? 'vd-margin-bottom-large' : 'vd-margin-bottom-medium' ]},[_vm._v(" GENERAL ")]):_vm._e(),(!_vm.canUpdateContacts)?_c('new-project-form-info-section'):_c('project-info-section',{on:{"init:default-payload":_vm.initDefaultPayload,"update:changed-payload":_vm.updateChangedPayload}}),_c('project-contacts',{attrs:{"contact-header-container-class":_vm.contactHeaderContainerClass,"can-update-contacts":_vm.canUpdateContacts,"contacts-label-text":!_vm.canUpdateContacts ? 'Project Contact' : 'Contacts',"contacts-label-class":!_vm.canUpdateContacts ? 'vd-light-grey' : 'vd-light-blue'}})],1),_c('project-brief-section',{class:['vd-padding-left-0', _vm.isMobileScreen ? 'col-12' : 'col-8'],attrs:{"display-overlay":!_vm.canUpdateContacts},on:{"init:default-payload":_vm.initDefaultPayload,"update:changed-payload":_vm.updateChangedPayload}})],1)]),(_vm.showContactForm)?_c('contact-form-modal'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }